
import MuiMobileStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import ErrorDialog from '../components/error-dialog';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import RegisterFormFrame from './frames/RegisterFormFrame';
import RegisterValidationFrame from './frames/RegisterValidationFrame';
import RegisterFinishFrame from './frames/RegisterFinishFrame';
import Analytics from '../base/Analytics';
import { Helmet } from "react-helmet";

function Register(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundImage: 'url(/assets/img/background/writing-1149962_1920.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: 'calc(100vh - 100px)',
            maxWidth: "1280px",
            margin: "auto"
        },
    }));

    const classes = useStyles();
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [emailToValidate, setEmailToValidate] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");
    const { t } = useTranslation();

    const nextStep = (data) => {
        if (data && data.config && data.config.data) {
            let res = JSON.parse(data.config.data);
            setEmailToValidate(res.email);
        }

        setActiveStep(activeStep => activeStep + 1);
    };

    const showError = (error) => {
        setErrorMsg(error.customMsg);
    }

    const handleClose = () => {
        setErrorMsg("");
    }

    const goToHome = () => {
        props.history.push('/home');
    };

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }
    }, [loadAnalytics]);

    return (
        <div className={classes.root}>

            <Helmet>
                <title data-react-helmet="true">Registre-se</title>
                <meta name="description" content="Registre-se para ganhar pontos e consultoria." data-react-helmet="true" />
                <link rel="canonical" href={`/register`} data-react-helmet="true" />
            </Helmet>

            <CssBaseline />
            <Container maxWidth="md" >
                <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

                <MuiMobileStepper activeStep={activeStep} orientation="horizontal" >
                    <Step key={0} >
                        <StepLabel>{t('step.register')}</StepLabel>
                    </Step>

                    <Step key={1} >
                        <StepLabel>{t('step.validate')}</StepLabel>
                    </Step>

                    <Step key={2} >
                        <StepLabel>{t('step.finish')}</StepLabel>
                    </Step>
                </MuiMobileStepper>

                {(() => {
                    switch (activeStep) {
                        case 0: return <RegisterFormFrame onSucess={nextStep} onError={showError} />
                        case 1: return <RegisterValidationFrame onSucess={nextStep} email={emailToValidate} onError={showError} />
                        case 2: return <RegisterFinishFrame onSucess={goToHome} onError={showError} />
                        default: return <RegisterFormFrame onSucess={nextStep} />
                    }
                })()}

            </Container >
        </div>
    );
}

export default Register;