// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useForm } from "react-hook-form"; //Controller
import { useTranslation } from 'react-i18next';
import Client from '../../base/Client';
import React from 'react';

function ForgotPasswordFormFrame(props) {
    const ajax = new Client();
    const { register, errors, handleSubmit } = useForm(); //control
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);

    const onSubmit = (data) => {
        //Colocando load
        setLoading(true);

        //executa a chamada
        ajax.send("post", "/pub/v1/password/reset", data)
            .then(response => {
                if (props.onSucess != null) {
                    props.onSucess(response);
                }
            })
            .catch(response => {
                if (props.onError != null) {
                    props.onError(response);
                }
            }).finally(() => {
                setLoading(false);
            });
    };

    return (<Box bgcolor="background.paper" p={2} borderRadius={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label={t('input.email')}
                        name="email"
                        required
                        inputRef={register({
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: t('input.invalid', { input: t('input.email') }),
                            }
                        })}
                        placeholder={t('input.email')}
                        size="small"
                        fullWidth
                        error={!!errors.email}
                        helperText={!!errors.email && errors.email.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.reset')}</Button>
                </Grid>
            </Grid>
        </form>
    </Box>)
}

export default ForgotPasswordFormFrame;