import { ErrorMessage } from '@hookform/error-message';
import { Button, Checkbox, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Editor } from '@tinymce/tinymce-react';
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import Analytics from '../base/Analytics';
import Client from '../base/Client';
import Utils from '../base/Utils';
import ErrorDialog from '../components/error-dialog';
import QuestionDialog from '../components/question-dialog';
import TextTitle from '../components/text-title';
import CopyrightFrame from './frames/CopyrightFrame';
// import Code from '../components/code';

function BlogRegistration() {

    const useStyles = makeStyles((theme) => ({
        paper: {
            flexGrow: 1,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        buttons: {
            marginTop: theme.spacing(2),
        },
        row: {
            cursor: "pointer",
            color: '#000',
            '&:hover': {
                background: theme.palette.primary.main,
            },
            "& td": {
                '&:hover': {
                    color: theme.palette.text.disabled,
                },
            },
        },
    }));

    const classes = useStyles();
    const { blogId } = useParams();

    const [blog, setBlog] = React.useState(null);
    const [category, setCategory] = React.useState(null);
    const [content, setContent] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [loadingBlog, setLoadingBlog] = React.useState(false);
    const [loadingCategory, setLoadingCategory] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const { register, errors, handleSubmit, setValue, control } = useForm();
    const [exitPage, setExitPage] = React.useState(false);
    const [reloadPage, setReloadPage] = React.useState(false);
    const ajax = new Client();
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const { t } = useTranslation();

    const closeHandler = () => {
        setErrorMsg("");

        if (exitPage) {
            backHandler();
        } else if (reloadPage) {
            let reloadLocation = `/registration/blog/${blog.id}`;
            document.location.href = reloadLocation;
        }
    }

    const deleteNoHandler = () => {
        setDeleteDialog(false);
    }

    const deleteYesHandler = () => {
        setLoading(true);

        ajax.send("delete", `/adm/v1/blog/${blog.id}`)
            .then(response => {
                if (response.status === 200) {
                    setBlog(null);
                    setErrorMsg(t('text.register.deleted'));
                    setExitPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
                setDeleteDialog(false);
            });
    }

    const backHandler = () => {
        document.location.href = "/registration/blog/search";
    }

    const editorHandler = (e, editor) => {
        setContent(e.target.getContent());
    }

    const deleteHAndler = () => {
        setDeleteDialog(true);
    }

    const onSubmit = (data) => {

        //Colocando content do Edit na chamada
        data.content = content;

        //Arruma o checkbox
        if (data.statusCheck === true) {
            data.active = 1;
        } else {
            data.active = 0;
        }
        data.statusCheck = undefined;

        //Colocando load
        setLoading(true);

        //executa a chamada
        let url = "/adm/v1/blog/";
        let action = "post";

        if (blog !== null && blog !== undefined) {
            url = `/adm/v1/blog/${blog.id}`;
            action = "put";
        }

        ajax.send(action, url, data)
            .then(response => {
                setBlog(response.data);

                if (response.status === 200) {
                    setErrorMsg(t('text.register.save'));
                } else if (response.status === 201) {
                    setErrorMsg(t('text.register.insert'));
                    setReloadPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
            });
    }

    const comboCategoryContent = () => {
        return (category !== null && category.map((row, index) => {
            return <MenuItem key={index} value={row.id} >{row.name}</MenuItem>;
        })
        )
    }

    const loadCategory = useCallback(() => {
        const client = new Client();
        setLoadingCategory(true);
        if (category === null) {
            client.send("get", "/adm/v1/product/category/", null)
                .then(data => {
                    if (data.data !== undefined && data.data !== null && data.status === 200) {
                        setCategory(data.data);
                    }
                }).catch(error => {
                    setErrorMsg(error.customMsg);
                }).finally(() => {
                    setLoadingCategory(false);
                });
        }
    }, [category]);

    const loadContent = useCallback(
        () => {
            const client = new Client();
            if (blog === null && blogId !== undefined) {

                setLoadingBlog(true);

                client.send("get", `/adm/v1/blog/${blogId}`, null)
                    .then(data => {
                        if (data.data !== undefined && data.data !== null && data.status === 200) {
                            let utils = new Utils();
                            setValue('title', data.data.title);
                            setValue('description', data.data.description);
                            setValue('dateCreated', utils.formatDateYYYYDDMM(data.data.dateCreated));
                            setValue("category_id", data.data.category.id);
                            setValue("authorName", data.data.authorName);
                            setValue("tags", data.data.tags);
                            setValue("authorName", data.data.authorName);
                            setValue("youtube", data.data.youtube);

                            if (data.data.active === 1) {
                                setValue("statusCheck", true);
                            } else {
                                setValue("statusCheck", false);
                            }

                            setContent(data.data.content);

                            //Arruma o checkbox
                            if (data.data.privy === 1) {
                                setValue("privyCheck", true);
                            } else {
                                setValue("privyCheck", false);
                            }

                            setBlog(data.data);
                        }
                    }).catch(error => {
                        setBlog({});
                        setErrorMsg(error.customMsg);
                    }).finally(() => {
                        setLoadingBlog(false);
                    });
            }
        },
        [blog, blogId, setValue],
    );

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        if (!errorMsg) {
            if (loadingCategory === false) {
                loadCategory();
            }

            if (loadingBlog === false) {
                loadContent();
            }
        }
    }, [loadCategory, loadContent, loadingBlog, loadingCategory, loadAnalytics, errorMsg]);

    return (
        <Container maxWidth="md" >

            <Helmet>
                <title data-react-helmet="true">Cadastrar Assunto</title>
                <meta name="description" content="Cadastro de postagens no blog" data-react-helmet="true" />
                <link rel="canonical" href={`/registration/blog/${blogId}`} data-react-helmet="true" />
            </Helmet>

            <QuestionDialog open={deleteDialog} yesHandler={deleteYesHandler} noHandler={deleteNoHandler} message={t('dialog.delete.content')} />
            <ErrorDialog onClose={closeHandler} errorMsg={errorMsg} />

            <TextTitle>{t('text.blog.register')}</TextTitle>


            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextField
                                variant="outlined"
                                label={t('input.title')}
                                name="title"
                                required
                                fullWidth
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }, maxLength: {
                                        value: 200,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }
                                })}
                                placeholder={t('input.title')}
                                size="small"
                                error={!!errors.title}
                                helperText={!!errors.title && errors.title.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth required variant="outlined" size="small">
                                <InputLabel htmlFor="comboCategory">{t('input.category')}</InputLabel>
                                <Controller
                                    control={control}
                                    name="category_id"
                                    defaultValue="null"
                                    error={!!errors.category_id}
                                    errorMsg={!!errors.category_id && errors.category_id.message}
                                    rules={{
                                        validate: {
                                            checkValue: v => {
                                                if (v === "null") {
                                                    return "Escolha uma categoria";
                                                } else {
                                                    return true;
                                                }
                                            },

                                        }
                                    }}
                                    as={
                                        <Select
                                            value
                                            label={t('input.category')}
                                            id="comboCategory">
                                            <MenuItem key={0} value="null">Nenhum</MenuItem>
                                            {comboCategoryContent()}
                                        </Select>
                                    }
                                />
                                <ErrorMessage errors={errors} name="category_id" render={({ message }) => <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled Mui-required MuiFormHelperText-marginDense">{message}</p>} />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                label={t('input.activated')}
                                control={
                                    <Controller
                                        name="statusCheck"
                                        control={control}
                                        defaultValue={false}
                                        render={({ onChange, value }) => (
                                            < Checkbox
                                                onChange={e => onChange(e.target.checked)}
                                                checked={value}
                                            />
                                        )}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label={t('input.youtube')}
                                name="youtube"
                                inputRef={register({
                                    minLength: {
                                        value: 1,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }, maxLength: {
                                        value: 200,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }
                                })}
                                placeholder={t('input.youtube')}
                                size="small"
                                fullWidth
                                error={!!errors.youtube}
                                helperText={!!errors.youtube && errors.youtube.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label={t('input.tags')}
                                name="tags"
                                required
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }, maxLength: {
                                        value: 100,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }
                                })}
                                placeholder={t('input.tags')}
                                size="small"
                                fullWidth
                                error={!!errors.tags}
                                helperText={!!errors.tags && errors.tags.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <Typography>{t('text.content')}</Typography>
                            <Editor
                                // className={classes.editorStyle}
                                onChange={editorHandler}
                                apiKey="3obog2tfr8d2fkp772lwgqqv2pu6yykn0smp22xg4xystnuy"
                                init={{
                                    //content_style: 'body {  height:500px }',
                                    //body_class: classes.editorStyle,
                                    //advcode casechange checklist export pageembed  advtable powerpaste formatpainter linkchecker a11ychecker permanentpen mediaembed tinymcespellchecker
                                    height: "500",
                                    skin: 'naked',
                                    selector: "#conteudo",
                                    forced_root_block: false,
                                    plugins: 'codesample  autolink lists media table link image',
                                    toolbar: 'undo redo | bold italic link | alignleft aligncenter alignright | code | codesample | image',
                                    contextmenu: 'link image table'
                                }}
                                initialValue={blog === null ? "" : blog.content}
                            />
                        </Grid>

                        {blogId !== undefined && <Grid item xs={12}>
                            <TextField
                                disabled
                                format="yyyy-MM-dd"
                                variant="outlined"
                                label={t('input.dateCreated')}
                                name="dateCreated"
                                type="date"
                                inputRef={register({
                                    setValueAs: (value) => value === "" ? null : value,
                                })}
                                size="small"
                                fullWidth
                                error={!!errors.dateCreated}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={errors.dateCreated && errors.dateCreated.message}
                            />
                        </Grid>}

                        {blogId !== undefined && <Grid item xs={12}>
                            <TextField
                                disabled
                                variant="outlined"
                                label={t('input.author')}
                                name="authorName"
                                type="text"
                                inputRef={register({
                                    setValueAs: (value) => value === "" ? null : value,
                                })}
                                size="small"
                                fullWidth
                                error={!!errors.authorName}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={errors.authorName && errors.authorName.message}
                            />
                        </Grid>}

                    </Grid>
                </Paper>

                <Grid container spacing={2} className={classes.buttons}>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={backHandler} >{t('button.back')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.save')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={deleteHAndler} color="secondary">{t('button.delete')}</Button>
                    </Grid>
                </Grid>
            </form>

            <CopyrightFrame />

        </Container>
    );
}

export default BlogRegistration;
