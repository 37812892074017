import Typography from '@material-ui/core/Typography';
import ErrorDialog from '../components/error-dialog';
import React, { useEffect } from "react";
import TextTitle from '../components/text-title';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import CopyrightFrame from './frames/CopyrightFrame';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import Client from '../base/Client';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import ScaleBox from "../components/scale-box";
import IntersectionObserver from "../components/intersection-observer";
import Analytics from '../base/Analytics';
import { Helmet } from "react-helmet";

function ProductSearch(props) {

    const useStyles = makeStyles((theme) => ({
        main: {
            //display: 'flex',
          //  backgroundImage: 'url(/assets/img/home/learn_back.jpg)',
            backgroundRepeat: "no",
            padding: theme.spacing(0) + 'px ' + theme.spacing(4) + 'px',
            width: '100%',
            maxWidth: "1280px",
            margin: "auto",
        },
        title: {
            display: 'inline-block',
            padding: theme.spacing(2),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
        },
        description: {
            display: 'inline-block',
            marginBottom: theme.spacing(0),
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(2),
            width: '100%',
        },
        searchPaper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        paperResult: {
            padding: theme.spacing(2),
        },
        serachBox: {
            paddingTop: theme.spacing(1),
        },
        logosContainer: {
            display: 'flex',
            margin: 'auto',
        },
        logos: {
            height: '64px',
        },
        gridRoot: {
            marginTop: theme.spacing(2),
            display: 'block',
        }
    }));

    const [loadAnalytics, setAnalytics] = React.useState(false);
    const { register, errors, handleSubmit } = useForm();
    const classes = useStyles();
    const [product, setProduct] = React.useState(null);
    const [productItem, setProductItem] = React.useState(null);
    const { t } = useTranslation();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const ajax = new Client();

    const onSubmit = (data) => {
        //Coloca o email recebido do pai
        data.email = props.email;

        //Colocando load
        setLoading(true);

        //executa a chamada
        ajax.send("get", `/pub/v1/product/?desc=${data.description}`)
            .then(response => {
                setProduct(response.data);

                //executa a chamada
                setLoading(true);
                ajax.send("get", `/pub/v1/product/item/?desc=${data.description}`)
                    .then(responseItem => {
                        setProductItem(responseItem.data);
                    })
                    .catch(responseItem => {
                        setErrorMsg(responseItem.customMsg);
                    }).finally(() => {
                        setLoading(false);
                    });
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
            });


    };

    const handleClose = () => {
        setErrorMsg("");
    }

    const contentNotLoad = (row, i) => {
        return (<Grid item xs={12} sm={4} key={i}>
            <Skeleton variant="rect" width={'100%'} height={118} />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
        </Grid>)
    }

    const buildSearchResult = () => {
        if (loading === true) {
            return ([...Array(6)].map(contentNotLoad));
        } else if (product === null) {
            return (
                <Grid item xs={12}>
                    <Paper className={classes.paperResult}  >
                        <Typography color="primary" variant="body1" gutterBottom >
                            {t('text.do.search')}
                        </Typography>
                    </Paper>
                </Grid>);
        } else if (product !== null && product.length === 0) {
            return (
                <Grid item xs={12}>
                    <Paper className={classes.paperResult} >
                        <Typography color="primary" variant="body1" gutterBottom >
                            {t('text.no.records')}
                        </Typography>
                    </Paper>
                </Grid>);
        } else if (product !== null && product.length > 0) {
            return product.map(productContent);
        }
    }

    const buildItemSearchResult = () => {
        if (loading === true) {
            return ([...Array(6)].map(contentNotLoad));
        } else if (productItem === null) {
            return (
                <Grid item xs={12}>
                    <Paper className={classes.paperResult}  >
                        <Typography color="primary" variant="body1" gutterBottom >
                            {t('text.do.search')}
                        </Typography>
                    </Paper>
                </Grid>);
        } else if (productItem !== null && productItem.length === 0) {
            return (
                <Grid item xs={12}>
                    <Paper className={classes.paperResult} >
                        <Typography color="primary" variant="body1" gutterBottom >
                            {t('text.no.records')}
                        </Typography>
                    </Paper>
                </Grid>);
        } else if (productItem !== null && productItem.length > 0) {
            return productItem.map(productContent);
        }
    }

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }
    }, [loadAnalytics]);

    const productContent = (row, i) => {
        return (
            <Grid item xs={12} key={i}>
                <IntersectionObserver key={i}>
                    <ScaleBox delayOrder={i}>
                        <Paper className={classes.paperResult}>
                            <Grid container wrap="nowrap" spacing={2}>
                                <Grid item className={classes.logosContainer}>
                                    <img className={classes.logos} src={row.logo} alt="Logo" ></img>
                                </Grid>

                                <Grid item xs zeroMinWidth>
                                    <Typography color="textSecondary" gutterBottom >
                                        {row.title}
                                    </Typography>

                                    <Typography variant="body2" component="p" gutterBottom>
                                        {row.description}
                                    </Typography>

                                    <Button color="primary" variant="contained" onClick={() => handlerClick(row)} size="small">{t('text.learn.more')}</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </ScaleBox>
                </IntersectionObserver>
            </Grid>)
    }

    const handlerClick = (row) => {
        if (row.productId) {
            window.location.href = `/product/${row.productId}/item/${row.id}`;
        } else {
            window.location.href = `/product/${row.id}`;
        }
    }

    return (<div className={classes.main} >

        <Helmet>
            <title data-react-helmet="true">Pesquisar Assunto</title>
            <meta name="description" content="Pesquisar assuntos cadastrados" data-react-helmet="true" />
            <link rel="canonical" href={`/product/search`} data-react-helmet="true" />
        </Helmet>

        <CssBaseline />
        <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

        <div className={classes.title}>
            <Typography variant="h4">:: Pesquisar os assuntos disponíveis :: </Typography>
        </div>

        <Paper className={classes.searchPaper}>
            <Typography color="primary" variant="body1" gutterBottom >
                {t('page.search.body1')}
            </Typography>
            <Box className={classes.searchBox}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={10}>
                            <TextField
                                variant="outlined"
                                label={t('input.product.name')}
                                name="description"
                                required
                                inputRef={register({
                                    required: t('input.required'),
                                    minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '15' })
                                    }, maxLength: {
                                        value: 15,
                                        message: t('input.invalid.size', { from: '2', to: '15' })
                                    }
                                })}
                                placeholder={t('input.product.name')}
                                size="small"
                                fullWidth
                                error={!!errors.description}
                                helperText={!!errors.description && errors.description.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.search')}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Paper>

        <div className={classes.gridRoot}>
            <TextTitle>{t('title.search.product')}</TextTitle>

            <Typography gutterBottom>{t("text.last.products.search.description")}</Typography>

            <Grid container spacing={2}>
                {buildSearchResult()}
            </Grid>
        </div>

        <div className={classes.gridRoot}>
            <TextTitle className={classes.searchTitle}>{t('title.search.item')}</TextTitle>

            <Typography gutterBottom>{t("text.last.news.search.description")}</Typography>

            <Grid container spacing={2}>
                {buildItemSearchResult()}
            </Grid>
        </div>

        <CopyrightFrame />
    </div >);
}

export default ProductSearch;