import { Button, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import Analytics from '../base/Analytics';
import Client from '../base/Client';
import Utils from '../base/Utils';
import ErrorDialog from '../components/error-dialog';
import QuestionDialog from '../components/question-dialog';
import TextTitle from '../components/text-title';
import CopyrightFrame from './frames/CopyrightFrame';

function ChapterRegistration() {

    const useStyles = makeStyles((theme) => ({
        paper: {
            flexGrow: 1,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        textMargin: {
            marginBottom: theme.spacing(2)
        },
        row: {
            cursor: "pointer",
            color: '#000',
            '&:hover': {
                background: theme.palette.primary.main,
            },
            "& td": {
                '&:hover': {
                    color: theme.palette.text.disabled,
                },
            },
        },
    }));

    const classes = useStyles();
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const ajax = new Client();
    const [exitPage, setExitPage] = React.useState(false);
    const [reloadPage, setReloadPage] = React.useState(false);
    const { chapterId, productId } = useParams();
    const { register, errors, handleSubmit, setValue, control } = useForm();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [chapter, setChapter] = React.useState(null);
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [loadingChapter, setLoadingChapter] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [connError, setConnError] = React.useState(false);

    const errorCloseHandler = () => {
        setErrorMsg("");

        if (exitPage) {
            backHandler();
        } else if (reloadPage) {
            let reloadLocation = `/registration/product/${productId}/chapter/${chapter.id}`;
            document.location.href = reloadLocation;
        }
    }

    const deleteHAndler = () => {
        setDeleteDialog(true);
    }

    const deleteNoHandler = () => {
        setDeleteDialog(false);
    }

    const deleteYesHandler = () => {

        setLoading(true);

        ajax.send("delete", `/adm/v1/product/${productId}/chapter/${chapterId}`)
            .then(response => {
                if (response.status === 200) {
                    setChapter(null);
                    setErrorMsg(t('text.register.deleted'));
                    setExitPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
            });
    }

    const backHandler = () => {
        document.location.href = `/registration/product/${productId}`;
    }

    const onSubmit = (data) => {
        //Colocando load
        setLoading(true);

        //Arruma o checkbox
        if (data.statusCheck === true) {
            data.active = 1;
        } else {
            data.active = 0;
        }
        data.statusCheck = undefined;

        //executa a chamada
        let url = `/adm/v1/product/${productId}/chapter/`;
        let action = "post";

        if (chapter !== null && chapter !== undefined) {
            url = `/adm/v1/product/${productId}/chapter/${chapter.id}`;
            action = "put";
        }

        ajax.send(action, url, data)
            .then(response => {
                setChapter(response.data);

                if (response.status === 200) {
                    setErrorMsg(t('text.register.save'));
                } else if (response.status === 201) {
                    setErrorMsg(t('text.register.insert'));
                    setReloadPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
            });
    }

    const itemDetailHandler = (id) => {
        document.location.href = `/registration/product/${productId}/chapter/${chapterId}/item/${id}`;
    }

    const itemInsertHandler = () => {
        document.location.href = `/registration/product/${productId}/chapter/${chapterId}/item/`;
    }

    const gridChapters = () => {
        return (
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TableContainer component={Paper} className={classes.table} >
                            <Table aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={{ width: 100 }}>Id</TableCell>
                                        <TableCell align="left">Assunto</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {chapter != null && chapter.productItems !== null && chapter.productItems.map((item, index) => (
                                        <TableRow key={index} className={classes.row} onClick={() => itemDetailHandler(item.id)}>
                                            <TableCell align="left">{item.id}</TableCell>
                                            <TableCell align="left">{item.title}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} >
                        <Button disabled={loading} variant="contained" onClick={itemInsertHandler} >{t('button.insert')}</Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    const loadContent = useCallback(
        () => {
            const client = new Client();
            if (chapter === null && chapterId !== undefined) {
                setLoadingChapter(true);
                client.send("get", `/adm/v1/product/${productId}/chapter/${chapterId}`, null)
                    .then(data => {
                        if (data.data !== undefined && data.data !== null && data.status === 200) {
                            let utils = new Utils();
                            setValue('title', data.data.title);
                            setValue('description', data.data.description);
                            setValue('chapterOrder', data.data.chapterOrder);
                            setValue('dateCreated', utils.formatDateYYYYDDMM(data.data.dateCreated));
                            setChapter(data.data);

                            //Arruma o checkbox
                            if (data.data.active === 1) {
                                setValue("statusCheck", true);
                            } else {
                                setValue("statusCheck", false);
                            }
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                        setConnError(true);
                    }).finally(() => {
                        setLoadingChapter(false);
                    });
            }
        },
        [chapter, productId, chapterId, setValue],
    );

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        if (connError === false && loadingChapter === false) {
            loadContent();
        }
    }, [loadContent, loadingChapter, connError, loadAnalytics]);

    return (
        <Container maxWidth="md" >
            <Helmet>
                <title data-react-helmet="true">Cadastrar Capítulos</title>
                <meta name="description" content="Cadastre um novo Capítulo para o curso" data-react-helmet="true" />
                <link rel="canonical" href={`/registration/product/${productId}/chapter/${chapterId}`} data-react-helmet="true" />
            </Helmet>

            <QuestionDialog open={deleteDialog} yesHandler={deleteYesHandler} noHandler={deleteNoHandler} message={t('dialog.delete.content')} />
            <ErrorDialog onClose={errorCloseHandler} errorMsg={errorMsg} />

            <TextTitle>{t('text.chapter.register')}</TextTitle>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextField
                                variant="outlined"
                                label={t('input.title')}
                                name="title"
                                required
                                fullWidth
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }, maxLength: {
                                        value: 200,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }
                                })}
                                placeholder={t('input.title')}
                                size="small"
                                error={!!errors.title}
                                helperText={!!errors.title && errors.title.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                variant="outlined"
                                label={t('input.description')}
                                name="description"
                                required
                                fullWidth
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }, maxLength: {
                                        value: 200,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }
                                })}
                                placeholder={t('input.description')}
                                size="small"
                                error={!!errors.description}
                                helperText={!!errors.description && errors.description.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                label={t('input.activated')}
                                control={
                                    <Controller
                                        name="statusCheck"
                                        control={control}
                                        defaultValue={false}
                                        render={({ onChange, value }) => (
                                            < Checkbox
                                                onChange={e => onChange(e.target.checked)}
                                                checked={value}
                                            />
                                        )}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label={t('input.order')}
                                name="chapterOrder"
                                required
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 1,
                                        message: t('input.invalid.size', { from: '2', to: '100' })
                                    }, maxLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '100' })
                                    }
                                })}
                                placeholder={t('input.order')}
                                size="small"
                                fullWidth
                                error={!!errors.chapterOrder}
                                helperText={!!errors.chapterOrder && errors.chapterOrder.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        {chapterId !== undefined && <Grid item xs={12}>
                            <TextField
                                disabled
                                format="yyyy-MM-dd"
                                variant="outlined"
                                label={t('input.dateCreated')}
                                name="dateCreated"
                                type="date"
                                inputRef={register({
                                    setValueAs: (value) => value === "" ? null : value,
                                })}
                                size="small"
                                fullWidth
                                error={!!errors.dateCreated}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={errors.dateCreated && errors.dateCreated.message}
                            />
                        </Grid>}
                    </Grid>
                </Paper>

                <TextTitle>{t('text.item.register')}</TextTitle>
                {chapter === null && <Typography className={classes.textMargin}>{t('text.product.save.chapter.warning')}</Typography>}

                {chapter !== null && gridChapters()}

                <Grid container spacing={2} className={classes.buttons}>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={backHandler} >{t('button.back')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.save')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={deleteHAndler} color="secondary">{t('button.delete')}</Button>
                    </Grid>
                </Grid>
            </form>


            <CopyrightFrame />
        </Container>);
}

export default ChapterRegistration;