
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import Typed from 'typed.js';
import Analytics from '../base/Analytics';
import Storages from '../base/Storages';
import BlogFrame from './frames/BlogFrame';
import CategoryFrame from './frames/CategoryFrame';
import CopyrightFrame from './frames/CopyrightFrame';
import HomeCarouselFrame from './frames/HomeCarouselFrame';
import HomeNewsFrame from './frames/HomeNewsFrame';
import HomeProductsFrame from './frames/HomeProductsFrame';
import WelcomeVideo from './frames/WelcomeVideo';

function Home() {
    const { t } = useTranslation();
    const [loadHome, setLoadHome] = React.useState(false);
    const [loadAnalytics, setAnalytics] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
          //  margin: theme.spacing(0),
            maxWidth: "1280px",
            margin: "auto",
        },
        title: {
            display: 'inline-block',
            padding: theme.spacing(2),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
        },
        description: {
            display: 'inline-block',
            marginBottom: theme.spacing(0),
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(2),
            width: '100%',
        },
        objetivo: {
            display: 'inline-block',
            // marginBottom: theme.spacing(4),
            padding: theme.spacing(4) + 'px ' + theme.spacing(4) + 'px',
            width: '100%',
        },
        bottomSpacing: {
            marginBottom: theme.spacing(4),
        },
        body1: {
            maxWidth: '1024px',
            margin: 'auto',
        }
    }));

    const classes = useStyles();

    const loadTerminalContent = useCallback(
        () => {
            if (loadHome) {
                new Typed('#objetivo', {
                    strings: [t("text.home.intro")],
                    typeSpeed: 30
                });
            }

        },
        [loadHome, t],
    );

    const verifyRedirect = useCallback(
        () => {
            if (loadHome === false) {
                const storages = new Storages();
                if (storages.getUser()) {
                    document.location.href = "/welcome";
                } else {
                    setLoadHome(true);
                }
            }
        },
        [loadHome],
    );

    useEffect(() => {
        if (loadAnalytics === false) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        verifyRedirect();
        loadTerminalContent();
    }, [loadTerminalContent, verifyRedirect, loadAnalytics]);

    return loadHome === true ? (
        <div className={classes.root} >
            <Helmet>
                <title data-react-helmet="true">Home</title>
                <meta name="description" content="Seja vem vindo à www.byiorio.com.br" data-react-helmet="true" />
                <link rel="canonical" href={`/home`} data-react-helmet="true" />
            </Helmet>

            <HomeCarouselFrame />

            <div className={classes.objetivo} >

                <div className={classes.title}>
                    <Typography variant="h4"> :: Objetivo :: </Typography>
                </div>

                <div className={classes.description}>
                    <Typography variant="subtitle1"><span id='objetivo'></span></Typography>
                </div>

            </div>

            <WelcomeVideo />

            <CategoryFrame />

            <HomeNewsFrame />

            <BlogFrame />

            <HomeProductsFrame />

            <CopyrightFrame />
        </div >
    ) : (
        <div className={classes.root}></div >
    );
}

export default Home;