import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Analytics from '../base/Analytics';
import BlogFrame from './frames/BlogFrame';
import CopyrightFrame from './frames/CopyrightFrame';
import HomeNewsFrame from './frames/HomeNewsFrame';
import HomeProductsFrame from './frames/HomeProductsFrame';
import HomeSubscriptionsFrame from './frames/HomeSubscriptionsFrame';
import CategoryFrame from './frames/CategoryFrame';
import UserPerfil from './frames/UserPerfil';
import WelcomeVideo from './frames/WelcomeVideo';

function HomeUser() {

    const [loadAnalytics, setAnalytics] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            maxWidth: "1280px",
            margin: "auto",
        },
    }));

    const classes = useStyles();

    useEffect(() => {

        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

    }, [loadAnalytics]);

    return (
        <div className={classes.root}>
            <Helmet>
                <title data-react-helmet="true">Home Logada</title>
                <meta name="description" content="Seja vem vindo à www.byiorio.com.br" data-react-helmet="true" />
                <link rel="canonical" href={`/welcome`} data-react-helmet="true" />
            </Helmet>

            <WelcomeVideo />

            <UserPerfil />

            <HomeSubscriptionsFrame />

            <CategoryFrame />

            <HomeNewsFrame />

            <BlogFrame />

            <HomeProductsFrame />

            <CopyrightFrame />
        </div>
    );
}

export default HomeUser;