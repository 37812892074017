import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Client from '../../base/Client';
import ErrorDialog from '../../components/error-dialog';
import IntersectionObserver from "../../components/intersection-observer";
import ScaleBox from "../../components/scale-box";

function HomeProductsFrame(props) {
    const { t } = useTranslation();

    const useStyles = makeStyles((theme) => ({
        main: {
            display: 'inline-block',
            padding: theme.spacing(8) + 'px ' + theme.spacing(4) + 'px',
            width: '100%',
            background: theme.palette.background.light,
        },
        title: {
            display: 'inline-block',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(0),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
            '@media (max-width:780px)': {
                '& h4': {
                    fontSize: "18pt",
                },
            },
        },
        description: {
            display: 'inline-block',
            marginBottom: theme.spacing(0),
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(2),
            width: '100%',
        },
        root: {
            display: "flex",
            paddingBottom: theme.spacing(2),
            boxSizing: 'border-box',
        },
        logos: {
            width: '128px',
            height: '128px',
        },
        cardRoot: {
            display: 'flex',
            flexDirection: 'column',
            height: '100% !important',
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.background.selected,
            },
            '&:hover *': {
            },
        },
        cardContent: {
            flex: '50%',
            verticalAlign: 'top',
            paddingBottom: '0px',
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        cardLogo: {
            background: '#403f3f',
            flex: '40%',
            textAlign: 'center',
            verticalAlign: 'top',
        },
        cardFooter: {
            flex: '10%',
            verticalAlign: 'bottom',
            alignSelf: 'center',
            padding: theme.spacing(2),
            '& button': {
                backgroundColor: '#403f3f',
                paddingBottom: theme.spacing(1),
                paddingTop: theme.spacing(1),
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            }
        },
    }));

    const classes = useStyles();
    const [products, setProducts] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState("");

    const loadContent = useCallback(
        () => {
            const client = new Client();
            client.send("get", "/pub/v1/product/last", null)
                .then(data => {
                    if (data.data !== undefined) {
                        setProducts(data.data);
                    } else {
                        setProducts([]);
                    }
                }).catch(error => {
                    setErrorMsg(error.customMsg);
                });
        },
        [],
    );

    const handleClose = () => {
        setErrorMsg("");
    }

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    const videosHtmls = (row, i) => {
        return (
            <Grid item xs={12} sm={6} md={4} key={i} className={classes.gridItem}>
                <IntersectionObserver key={i} css={{ height: '100%' }}>
                    <ScaleBox delayOrder={i} css={{ height: '100%' }}>
                        <Card raised onClick={() => handlerClick(row.id)} className={classes.cardRoot}>
                            <CardContent className={classes.cardLogo}>
                                <img className={classes.logos} src={row.logo} alt="Logo"></img>
                            </CardContent>

                            <CardContent className={classes.cardContent}>
                                <Typography color="textSecondary" gutterBottom >
                                    {row.title}
                                </Typography>
                                <Typography variant="body2" component="p" >
                                    {row.description}
                                </Typography>
                            </CardContent>

                            <CardActions className={classes.cardFooter}>
                                <Button color="primary" onClick={() => handlerClick(row.id)} size="small">{t('text.learn.more')}</Button>
                            </CardActions>
                        </Card>
                    </ScaleBox>
                </IntersectionObserver>
            </Grid>)
    }

    const contentNotLoad = (row, i) => {
        return (<Grid item xs={12} sm={4} key={i}>
            <Skeleton variant="rect" width={'100%'} height={118} />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
        </Grid>)
    }

    const handlerClick = (productId) => {
        window.location.href = `/product/${productId}`;
    }

    return (
        <div className={classes.main}>
            <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

            <div className={classes.title}>
                <Typography variant="h4"> :: {t("text.last.products")}  :: </Typography>
            </div>

            <div className={classes.description}>
                <Typography variant="subtitle1">{t("text.last.products.description")}</Typography>
            </div>

            <Grid container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
                className={classes.root}
            >
                {products !== null ? (
                    (products.length > 0 ? products.map(videosHtmls) :
                        <Grid item>
                            <Typography component="p">
                                {t('text.products.empty')}
                            </Typography>
                        </Grid>
                    )
                ) : (
                    [...Array(3)].map(contentNotLoad)
                )}

            </Grid>
        </div>
    );
}

export default HomeProductsFrame;