import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useCallback, useEffect } from "react";
// import { useTranslation } from 'react-i18next';
import Client from '../../base/Client';
import ErrorDialog from '../../components/error-dialog';

function UserPerfil(props) {
    // const { t } = useTranslation();

    const useStyles = makeStyles((theme) => ({
        main: {
            //display: 'inline-block',
           // paddingTop: theme.spacing(0),
            width: '100%',
            padding: theme.spacing(8) + 'px ' + theme.spacing(4) + 'px',
            background: theme.palette.background.dark,
        },
        title: {
            display: 'inline-block',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(0),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
            '@media (max-width:780px)': {
                '& h4': {
                    fontSize: "18pt",
                },
            },
        },
        description: {
            display: 'inline-block',
            marginBottom: theme.spacing(0),
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(2),
            width: '100%',
        },
        root: {
            display: "flex",
            paddingBottom: theme.spacing(2),
            boxSizing: 'border-box',
        },
        paper: {
            padding: theme.spacing(2),
        },
        logos: {
            width: '128px',
            height: '128px',
        },
        cardRoot: {
            display: 'flex',
            flexDirection: 'column',
            height: '100% !important',
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.background.selected,
            },
            '&:hover *': {
            },
        },
        cardContent: {
            flex: '30%',
            verticalAlign: 'top',
            paddingBottom: '0px',
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        cardLogo: {
            background: '#403f3f',
            flex: '40%',
            textAlign: 'center',
            verticalAlign: 'top',
        },
        cardFooter: {
            flex: '10%',
            verticalAlign: 'bottom',
            alignSelf: 'center',
            padding: theme.spacing(2),
            '& button': {
                backgroundColor: '#403f3f',
                paddingBottom: theme.spacing(1),
                paddingTop: theme.spacing(1),
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            }
        },
        newsLogo: {
            height: '32px',
            width: '32px',
            display: 'none'
        }
    }));

    const classes = useStyles();
    const [user, setUser] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState("");

    const loadContent = useCallback(
        () => {
            const client = new Client();

            if (user === null) {
                client.send("get", "/priv/v1/user/", null)
                    .then(data => {
                        if (data.status === 200) {
                            if (data.data !== undefined) {
                                setUser(data.data);
                            }
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                    });
            }
        },
        [user],
    );

    const handleClose = () => {
        setErrorMsg("");
    }

    useEffect(() => {
        loadContent();
    }, [loadContent]);


    const userContent = (userLoaded) => {
        return (<Card className={classes.cardRoot}>
            <CardContent className={classes.cardLogo}>
                <img className={classes.logos} src="/assets/img/user.png" height="64px" alt="Foto usuário" />
            </CardContent>

            <CardContent className={classes.cardContent}>
                <Typography color="textSecondary" gutterBottom >
                    {userLoaded.data.firstName}   {userLoaded.data.lastName}
                </Typography>

                <Typography variant="body" component="p">
                    E-mail: {userLoaded.data.email}
                </Typography>

                <Typography variant="body" component="p">
                    Total de Pontos: {(userLoaded.data.totalPoints !== null ? userLoaded.data.totalPoints : 0)}
                </Typography>
            </CardContent>
        </Card>)
    }

    const contentNotLoad = () => {
        return (<Grid item xs={12} sm={12} key={1}>
            <Skeleton variant="rect" width='100%' height={118} />
        </Grid>)
    }

    return (
        <div className={classes.main}>
            <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

            <div className={classes.title}>
                <Typography variant="h4"> :: Usuário :: </Typography>
            </div>

            {user !== null ? (userContent(user)) : (contentNotLoad())}
        </div >
    );
}

export default UserPerfil;