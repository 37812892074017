
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Analytics from '../base/Analytics';
import CopyrightFrame from './frames/CopyrightFrame';
import CategoryFrame from './frames/CategoryFrame';

function ProductCategory() {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(0) + 'px ' + theme.spacing(4) + 'px',
            maxWidth: "1280px",
            margin: "auto",
        },
    }));

    const classes = useStyles();
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const { categoryId } = useParams();

    useEffect(() => {

        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

    }, [loadAnalytics]);

    return (
        <div className={classes.root} >
            <Helmet>
                <title data-react-helmet="true">Categorias</title>
                <meta name="description" content="Categorias disponíveis para aprender" data-react-helmet="true" />
                <link rel="canonical" href={`/product/category/${categoryId}`} data-react-helmet="true" />
            </Helmet>

            <CategoryFrame />

            <CopyrightFrame />
        </div >
    );
}

export default ProductCategory;