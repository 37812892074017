import { useEffect } from "react";
import Prism from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-bash';
import 'prismjs/themes/prism-okaidia.css';
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';

export default function Code(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
            marginTop: theme.spacing(2),
            '& a': {
                textDecoration: 'none',
                color: theme.palette.text.secondary,
                '&:active': {
                    color: theme.palette.text.primary,
                },
            },
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        setTimeout(() => Prism.highlightAll(), 300)
    }, []);

    return (<div className={classes.root} {...props}>{parse(props.children)}</div>);
}
