// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Client from '../../base/Client';
import React from 'react';

function RegisterFormFrame(props) {
    const ajax = new Client();
    const { register, errors, handleSubmit, watch } = useForm(); //control
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);

    const onSubmit = (data) => {
        //Colocando load
        setLoading(true);

        //executa a chamada
        ajax.send("post", "/pub/v1/register/", data)
            .then(response => {
                if (props.onSucess != null) {
                    props.onSucess(response);
                }
            })
            .catch(response => {
                if (props.onError != null) {
                    props.onError(response);
                }
            }).finally(() => {
                setLoading(false);
            });

    };

    return (<Box bgcolor="background.paper" p={2} borderRadius={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField variant="outlined"
                        label={t('input.firstname')}
                        name="firstName"
                        required
                        fullWidth
                        inputRef={register({
                            required: t('input.required')
                            , minLength: {
                                value: 2,
                                message: t('input.invalid.size', { from: '2', to: '30' })
                            }, maxLength: {
                                value: 30,
                                message: t('input.invalid.size', { from: '2', to: '30' })
                            }
                        })}
                        placeholder={t('input.firstname')}
                        size="small"
                        error={!!errors.firstName}
                        helperText={!!errors.firstName && errors.firstName.message}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField variant="outlined"
                        label={t('input.lastname')}
                        name="lastName"
                        required
                        inputRef={register}
                        placeholder={t('input.lastname')}
                        size="small"
                        fullWidth
                        error={!!errors.lastName}
                        helperText={!!errors.lastName && errors.lastName.message}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label={t('input.email')}
                        name="email"
                        required
                        inputRef={register({
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: t('input.invalid', { input: t('input.email') }),
                            }
                        })}
                        placeholder={t('input.email')}
                        size="small"
                        fullWidth
                        error={!!errors.email}
                        helperText={!!errors.email && errors.email.message}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        format="yyyy-MM-dd"
                        variant="outlined"
                        label={t('input.birthday')}
                        name="birthday"
                        type="date"
                        inputRef={register({
                            setValueAs: (value) => value === "" ? null : value,
                        })}
                        size="small"
                        fullWidth
                        error={!!errors.birthday}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        helperText={errors.birthday && errors.birthday.message}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField variant="outlined"
                        label={t('input.password')}
                        name="password"
                        required
                        fullWidth
                        type="password"
                        inputRef={register({
                            required: t('input.required')
                            , minLength: {
                                value: 6,
                                message: t('input.invalid.size', { from: '6', to: '15' })
                            }, maxLength: {
                                value: 15,
                                message: t('input.invalid.size', { from: '6', to: '15' })
                            }
                        })}
                        placeholder={t('input.password')}
                        size="small"
                        error={!!errors.password}
                        helperText={!!errors.password && errors.password.message}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField variant="outlined"
                        label={t('input.repassword')}
                        name="repassword"
                        required
                        fullWidth
                        type="password"
                        inputRef={register({
                            required: 'this is a required',
                            minLength: {
                                value: 6,
                                message: t('input.invalid.size', { from: '6', to: '15' })
                            }, maxLength: {
                                value: 15,
                                message: t('input.invalid.size', { from: '6', to: '15' })
                            },
                            validate: (value) => {
                                if (value !== watch('password')) {
                                    return t('input.repassword.diff');
                                } else {
                                    return true
                                }
                            }
                        })}
                        placeholder={t('input.repassword')}
                        size="small"
                        error={!!errors.repassword}
                        helperText={!!errors.repassword && errors.repassword.message}
                    />
                </Grid>


                {/* <Grid item xs={12}>
                    <FormControl fullWidth required variant="outlined" size="small">
                        <InputLabel htmlFor="combo-select">Age</InputLabel>
                        <Controller
                            control={control}
                            name="combo"
                            as={
                                <Select
                                    label="Age"
                                    id="combo-select">

                                    <MenuItem key={0} value="0">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem key={10} value={10}>Ten</MenuItem>
                                    <MenuItem key={20} value={20}>Twenty</MenuItem>
                                    <MenuItem key={30} value={30}>Thirty</MenuItem>
                                </Select>
                            }
                        />
                    </FormControl>
                </Grid> */}

                <Grid item xs={12}>
                    <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.register')}</Button>
                </Grid>
            </Grid>
        </form>
    </Box>)
}

export default RegisterFormFrame;