
import CopyrightFrame from './frames/CopyrightFrame';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextTitle from '../components/text-title';
import ErrorDialog from '../components/error-dialog';
import React, { useEffect, useCallback } from "react";
import Grid from '@material-ui/core/Grid';
import { TextField, Container } from '@material-ui/core';
import Client from '../base/Client';
import { useForm } from "react-hook-form";
import Analytics from '../base/Analytics';
import { Helmet } from "react-helmet";

function BlogRegistrationSearch() {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            margin: theme.spacing(2),
            marginTop: '0px',
        },
        table: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        searchGrid: {
            marginBottom: theme.spacing(2),
        },
        row: {
            cursor: "pointer",
            color: '#000',
            '&:hover': {
                background: theme.palette.primary.main,
            },
            "& td": {
                '&:hover': {
                    color: theme.palette.text.disabled,
                },
            },
        }

    }));

    const classes = useStyles();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [blog, setBlog] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const { register, errors, handleSubmit } = useForm();
    const ajax = new Client();
    const [loadAnalytics, setAnalytics] = React.useState(false);

    const handlerClick = () => {
        window.location.href = "/registration/blog/"
    }

    const handleClose = () => {
        setErrorMsg("");
    }

    const handlerClearClick = () => {
        loadContent();
    }

    const onSubmit = (data) => {
        //Colocando load
        setLoading(true);

        //executa a chamada
        ajax.send("get", `/adm/v1/blog/?desc=${data.description}`)
            .then(response => {
                if (response.data !== undefined && response.data !== "") {
                    setBlog(response.data);
                }else{
                    setBlog([]);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
                setBlog([]);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handlerProductClick = (id) => {
        document.location.href = `/registration/blog/${id}`;
    }

    const loadContent = useCallback(
        () => {
            const client = new Client();
            if (blog === null) {
                setLoading(true);

                client.send("get", "/adm/v1/blog/", null)
                    .then(data => {
                        if (data.data !== undefined) {
                            setBlog(data.data);
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                        setBlog([]);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        },
        [blog],
    );

    useEffect(() => {

        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        if (loading === false) {
            loadContent();
        }

    }, [loadContent, loading, loadAnalytics]);

    return (
        <Container maxWidth="md" >
            <Helmet>
                <title data-react-helmet="true">Pesquisar Blog</title>
                <meta name="description" content="Pesquisar blog cadastrados" data-react-helmet="true" />
                <link rel="canonical" href={`/registration/blog/search`} data-react-helmet="true" />
            </Helmet>

            <div className={classes.root}>
                <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

                <TextTitle>{t('text.title.blog')}</TextTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} className={classes.searchGrid}>
                        <Grid item xs={12} sm={8}>

                            <TextField
                                variant="outlined"
                                label={t('input.blog.name')}
                                name="description"
                                inputRef={register({
                                    minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '15' })
                                    }, maxLength: {
                                        value: 15,
                                        message: t('input.invalid.size', { from: '2', to: '15' })
                                    }
                                })}
                                placeholder={t('input.product.name')}
                                size="small"
                                fullWidth
                                error={!!errors.description}
                                helperText={!!errors.description && errors.description.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.search')}</Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button fullWidth disabled={loading} variant="contained" type="reset" color="primary" onClick={() => handlerClearClick()}>{t('button.clear')}</Button>
                        </Grid>
                    </Grid>
                </form>

                <TableContainer component={Paper} className={classes.table} >
                    <Table aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{ width: 100 }}>Id</TableCell>
                                <TableCell align="left">Assunto</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {blog !== null && blog.map((product, index) => (
                                <TableRow key={index} className={classes.row} onClick={() => handlerProductClick(product.id)}>
                                    <TableCell align="left">{product.id}</TableCell>
                                    <TableCell align="left">{product.title}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Button fullWidth color="primary" variant="contained" onClick={() => handlerClick()} >{t('button.new')}</Button>

                <CopyrightFrame />
            </div>
        </Container>
    );
}

export default BlogRegistrationSearch;