class Analytics {

    constructor() {
        this.ga_property = "G-TZRZZW3GVC";
        this.viewed = false;
    }

    pageView() {
        if (this.viewed === false) {
            if (window.gtag) {
                window.gtag('event', 'page_view', {
                    page_title: window.location.pathname,
                    page_location: window.location.href,
                    page_path: window.location.pathname + window.location.search,
                    send_to: 'G-TZRZZW3GVC'
                });
                this.viewed = true;
                ////console.log(window.location.pathname + window.location.search);
            }

        }
    }

}

export default Analytics;