import { Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

function RequestContentSuccess() {

    const useStyles = makeStyles((theme) => ({
        main: {
            display: 'inline-block',
            padding: theme.spacing(2) + 'px ' + theme.spacing(0) + 'px',
            width: '100%',
        },
        title: {
            display: 'inline-block',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(0),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
            '@media (max-width:780px)': {
                '& h4': {
                    fontSize: "18pt",
                },
            },
        },
        paper: {
            padding: theme.spacing(4),
            width: '100%',
        },
        buttons: {
            width: '100%',
        },
        root: {
            padding: theme.spacing(4),
            width: '100%',
            paddingTop: theme.spacing(0),
        },
        popper: {
            width: '100%',
        },
    }));

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.main}>

            <div className={classes.title}>
                <Typography variant="h4"> :: Uso dos pontos efetivado :: </Typography>
            </div>

            <Container maxWidth="sm">
                <Typography variant="p"> {t("market.purchase.success")} </Typography>
                <img src="/assets/img/party_popper.png" className={classes.popper} alt="Sucesso" />
                <Button variant="contained" color="secondary" onClick={() => { document.location.href = '/' }}>Voltar</Button>
            </Container>

        </div>
    );
}

export default RequestContentSuccess;