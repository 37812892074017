import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import ArticleIcon from '@material-ui/icons/Add';
import ArrowBackIosTwoToneIcon from '@material-ui/icons/ArrowBackIosTwoTone';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import SearchIcon from '@material-ui/icons/Search';
import StorefrontIcon from '@material-ui/icons/Storefront';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Client from '../../base/Client';
import Storages from '../../base/Storages';
import ElevationScroll from '../../components/elevationScroll';
import ErrorDialog from '../../components/error-dialog';
import ReceiptIcon from '@material-ui/icons/Receipt';

function NavFrame(props) {
    const history = useHistory();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [menuState, setMenuState] = React.useState(false);
    const storages = new Storages();
    const useStyles = makeStyles((theme) => ({
        root: {
            height: "100%",
        },
        drawer: {
            width: "240px",
        },
        menuButton: {
            paddingLeft: theme.spacing(2.75),
            cursor: 'pointer',
            height: "25px",
        },
        menuLogo: {
            textAlign: "center",
            height: "22px",
        },
        backButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(2),
            cursor: 'pointer',
        },
        logo: {
            cursor: 'pointer',
            height: '20px',

        },
        navbarStatic: {
            position: 'relative !important',
            height: '100px',
        },
        navbarFloat: {
            position: 'fixed !important',
            // opacity: '80%',
            backgroundColor: theme.palette.background.default + ' !important',
            height: '50px',
        }
    }));

    const classes = useStyles();

    const toggleDrawer = (openned) => (event) => {
        if (event !== undefined &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setMenuState(openned);
    };

    const changePageTo = (page) => {
        history.push(page);
        setMenuState(false);
    }


    const handleClose = () => {
        setErrorMsg("");
    }

    const logout = () => {

        const ajax = new Client();

        ajax.send("delete", "/pub/v1/login/", null)
            .then(response => {
                if (response.status === 204) {
                    storages.clean();
                    window.location.href = '/home';
                }
            })
            .catch(error => {
                if (error != null) {
                    setErrorMsg(error.customMsg);
                }
            });
    }

    return (
        <React.Fragment>
            <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />
            <CssBaseline />
            <SwipeableDrawer
                classes={{
                    paper: classes.drawer,
                }}
                className={classes.drawer}
                anchor='left'
                open={menuState}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <div className={classes.backButton} onClick={toggleDrawer(false)}>
                    <ArrowBackIosTwoToneIcon color='primary' />
                </div>

                <ListItem button key={0} onClick={() => changePageTo('/home')}>
                    <ListItemIcon><HomeIcon color='primary' /></ListItemIcon>
                    <ListItemText primary='Home' />
                </ListItem>

                {<ListItem button key={1} onClick={() => changePageTo('/product/search')}>
                    <ListItemIcon><SearchIcon color='primary' /></ListItemIcon>
                    <ListItemText primary='Pesquisar Assuntos' />
                </ListItem>}

                {<ListItem button key={11} onClick={() => changePageTo('/market')}>
                    <ListItemIcon><StorefrontIcon color='primary' /></ListItemIcon>
                    <ListItemText primary='Usar meus Pontos' />
                </ListItem>}

                {<ListItem button key={111} onClick={() => changePageTo('/market/transactions')}>
                    <ListItemIcon><ReceiptIcon color='primary' /></ListItemIcon>
                    <ListItemText primary='Consultar meu Extrato' />
                </ListItem>}

                {<ListItem button key={3} onClick={() => changePageTo('/about')}>
                    <ListItemIcon><InfoIcon color='primary' /></ListItemIcon>
                    <ListItemText primary='Sobre' />
                </ListItem>}

                {storages.getUser() !== null && storages.getUser().perfil === 2 ? <Divider /> : ""}

                {storages.getUser() !== null && storages.getUser().perfil === 2 ?
                    <ListItem button key={4} onClick={() => changePageTo('/registration/product/search')}>
                        <ListItemIcon><ArticleIcon color='primary' /></ListItemIcon>
                        <ListItemText primary='Cadastro Assunto' />
                    </ListItem> : ""}

                {storages.getUser() !== null && storages.getUser().perfil === 2 ?
                    <ListItem button key={5} onClick={() => changePageTo('/registration/blog/search')}>
                        <ListItemIcon><ArticleIcon color='primary' /></ListItemIcon>
                        <ListItemText primary='Cadastro Blog' />
                    </ListItem> : ""}

                {storages.getUser() !== null && storages.getUser().perfil === 2 ?
                    <ListItem button key={6} onClick={() => changePageTo('/registration/market/search')}>
                        <ListItemIcon><ArticleIcon color='primary' /></ListItemIcon>
                        <ListItemText primary='Cadastro Mercado' />
                    </ListItem> : ""}

                {storages.getUser() !== null && storages.getUser().perfil === 2 ?
                    <ListItem button key={61} onClick={() => changePageTo('/registgration/market/transactions/search')}>
                        <ListItemIcon><ArticleIcon color='primary' /></ListItemIcon>
                        <ListItemText primary='Transações Mercado' />
                    </ListItem> : ""}

                <Divider />

                {storages.getUser() === null ? (<ListItem button key={4} onClick={() => changePageTo('/login')}>
                    <ListItemIcon><AccountCircleTwoToneIcon color='primary' /></ListItemIcon>
                    <ListItemText primary='Entrar' />
                </ListItem>) : (
                    <ListItem button key={7} onClick={() => logout()}>
                        <ListItemIcon><AccountCircleTwoToneIcon color='primary' /></ListItemIcon>
                        <ListItemText primary='Sair' />
                    </ListItem>
                )}
            </SwipeableDrawer >

            <ElevationScroll classStatic={classes.navbarStatic} classFloat={classes.navbarFloat} threshold="0">
                <AppBar className={classes.navbar} color="transparent" elevation={0}>
                    <Grid container alignContent="center" className={classes.root}>
                        <Grid item xs className={classes.menuButton}>
                            <MenuTwoToneIcon color='primary' onClick={toggleDrawer(true)}></MenuTwoToneIcon>
                        </Grid>

                        <Grid item xs className={classes.menuLogo}>
                            <img src="/assets/img/background/logo-byiorio.png" className={classes.logo} alt="byiorio" onClick={() => changePageTo('/home')} />
                        </Grid>

                        <Grid item xs >
                            &nbsp;
                        </Grid>
                    </Grid>
                </AppBar>
            </ElevationScroll>
        </React.Fragment >
    )
}

export default NavFrame;