import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

function TextTitle(props) {

    const useStyles = makeStyles((theme) => ({
        brackets: {
            color: theme.palette.text.secundary,
        },
        text: {
            color: theme.palette.text.primary,
        },
        root: {
        }
    }));

    const classes = useStyles();

    return ( <Typography  gutterBottom color="textSecondary" variant="h6" {...props} className={classes.root} >
        <span className={classes.brackets}>&#123;</span>
        <span className={classes.text}>&nbsp;{props.children}&nbsp;</span>
        <span className={classes.brackets}>&#125;</span>
    </Typography>)

}

export default TextTitle;