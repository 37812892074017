import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from "react";
import YouTube from 'react-youtube';

function WelcomeVideo(props) {
    // const { t } = useTranslation();

    const useStyles = makeStyles((theme) => ({
        main: {
            backgroundImage: 'url(/assets/img/home/new_back.jpg)',
            display: 'inline-block',
            padding: theme.spacing(8) + 'px ' + theme.spacing(4) + 'px',
            paddingTop: theme.spacing(4),
            width: '100%',
            background: theme.palette.background.light,
        },
        title: {
            display: 'inline-block',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(0),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
            '@media (max-width:780px)': {
                '& h4': {
                    fontSize: "18pt",
                },
            },
        },
        description: {
            display: 'inline-block',
            marginBottom: theme.spacing(0),
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(2),
            width: '100%',
        },
        root: {
            display: "flex",
            paddingBottom: theme.spacing(2),
            boxSizing: 'border-box',
        },
        paper: {
            padding: theme.spacing(2),
        },
        logos: {
            width: '128px',
            height: '128px',
        },
        videoMain:{
            background: theme.palette.background.light,
        },
        video: {
            margin: 'auto',
            display: 'block',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            }
        },
        videoRoot: {
            marginTop: theme.spacing(2),
            width: '100%',
        },
    }));

    const classes = useStyles();

    // useEffect(() => {
    // }, []);

    return (
        <div className={classes.main}>

            <div className={classes.title}>
                <Typography variant="h4"> :: Como funciona o site ? :: </Typography>
            </div>

            <YouTube
                videoId={"RpGt3Gvwuiw"}
                id={'video'}
                className={classes.video}
                containerClassName={classes.videoRoot}
            ></YouTube>
        </div >
    );
}

export default WelcomeVideo;