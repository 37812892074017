
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import Analytics from '../base/Analytics';
import Client from '../base/Client';
import Utils from '../base/Utils';
import ErrorDialog from '../components/error-dialog';
import QuestionDialog from '../components/question-dialog';
import TextTitle from '../components/text-title';
import CopyrightFrame from './frames/CopyrightFrame';

function MarketTransactionRegistration() {

    const useStyles = makeStyles((theme) => ({
        paper: {
            flexGrow: 1,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        buttons: {
            marginTop: theme.spacing(2),
        },
        row: {
            cursor: "pointer",
            color: '#000',
            '&:hover': {
                background: theme.palette.primary.main,
            },
            "& td": {
                '&:hover': {
                    color: theme.palette.text.disabled,
                },
            },
        },
    }));

    const classes = useStyles();
    const { transactionId } = useParams();

    const [transaction, setTransaction] = React.useState(null);
    const [cannotChange, setCannotChange] = React.useState(false);
    const [status, setStatus] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [loadingProduct, setLoadingProduct] = React.useState(false);
    const [loadingStatus, setLoadingStatus] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const { register, errors, handleSubmit, setValue, control } = useForm();
    const [exitPage, setExitPage] = React.useState(false);
    const [reloadPage, setReloadPage] = React.useState(false);
    const ajax = new Client();
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const { t } = useTranslation();

    const closeHandler = () => {
        setErrorMsg("");

        if (exitPage) {
            backHandler();
        } else if (reloadPage) {
            let reloadLocation = `/registgration/market/transactions/${transaction.id}`;
            document.location.href = reloadLocation;
        }
    }

    const noRefoundHandler = () => {
        setDeleteDialog(false);
    }

    const revoundHandler = () => {
        setLoading(true);

        ajax.send("delete", `/adm/v1/market/transaction/${transaction.id}`)
            .then(response => {
                if (response.status === 200) {
                    setTransaction(null);
                    setErrorMsg(t('text.refound.done'));
                    setExitPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
                setDeleteDialog(false);
            });
    }

    const backHandler = () => {
        document.location.href = "/registgration/market/transactions/search";
    }

    const estornoHandler = () => {
        setDeleteDialog(true);
    }

    const onSubmit = (data) => {

        //Colocando load
        setLoading(true);

        ajax.send("put", `/adm/v1/market/transaction/${transaction.id}`, data)
            .then(response => {
                setTransaction(response.data);

                if (response.status === 200) {
                    setErrorMsg(t('text.register.save'));
                    setReloadPage(true);
                } else if (response.status === 201) {
                    setErrorMsg(t('text.register.insert'));
                    setReloadPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
                setReloadPage(true);
            }).finally(() => {
                setLoading(false);
            });
    }

    const comboStatusContent = () => {
        return (status !== null && status.map((row, index) => {
            return <MenuItem key={index} value={row.code} >{row.description}</MenuItem>;
        })
        )
    }

    const loadStatus = useCallback(() => {
        if (status === null) {
            setLoadingStatus(true);
            const client = new Client();
            client.send("get", `/pub/v1/market/transaction/status`, null)
                .then(data => {
                    if (data.data !== undefined && data.data !== null && data.status === 200) {
                        setStatus(data.data);
                    }
                }).catch(error => {
                    setErrorMsg(error.customMsg);
                }).finally(() => {
                    setLoadingStatus(false);
                });
        }
    }, [status]);

    const loadContent = useCallback(
        () => {
            const client = new Client();
            if (transaction === null && transactionId !== undefined) {

                setLoadingProduct(true);

                client.send("get", `/adm/v1/market/transaction/${transactionId}`, null)
                    .then(data => {
                        if (data.data !== undefined && data.data !== null && data.status === 200) {
                            let utils = new Utils();
                            setValue('price', data.data.price);
                            setValue('finishedDate', utils.formatDateYYYYDDMM(data.data.finishedDate));
                            setValue("status_id", data.data.statusId);
                            setValue("userObservation", data.data.userObservation);
                            setValue("dateCreated", utils.formatDateYYYYDDMM(data.data.createDate));
                            setValue('user', data.data.user.firstName + " " + data.data.user.lastName);
                            setValue("operatorObservation", data.data.operatorObservation);

                            //Se for finalizado ou estornado nao pode fazer nada, revalidado no back
                            if (data.data.statusId ===2 || data.data.statusId ===3){
                                setCannotChange(true);
                            }

                            setTransaction(data.data);
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                    }).finally(() => {
                        setLoadingProduct(false);
                    });
            }
        },
        [transaction, transactionId, setValue],
    );

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        if (!errorMsg) {
            if (loadingStatus === false) {
                loadStatus();
            }

            if (loadingProduct === false) {
                loadContent();
            }
        }
    }, [loadStatus, loadContent, loadingProduct, loadingStatus, loadAnalytics, errorMsg]);

    return (
        <Container maxWidth="md" >
            <QuestionDialog open={deleteDialog} yesHandler={revoundHandler} noHandler={noRefoundHandler} message={t('dialog.refound.content')} />
            <ErrorDialog onClose={closeHandler} errorMsg={errorMsg} />

            <TextTitle>{t('text.transaction.title')}</TextTitle>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                disabled
                                variant="outlined"
                                label={t('input.user')}
                                name="user"
                                required
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '100' })
                                    }, maxLength: {
                                        value: 100,
                                        message: t('input.invalid.size', { from: '2', to: '100' })
                                    }
                                })}
                                placeholder={t('input.user')}
                                size="small"
                                fullWidth
                                error={!!errors.user}
                                helperText={!!errors.user && errors.user.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                disabled
                                format="yyyy-MM-dd HH:mm:ss"
                                variant="outlined"
                                label={t('input.dateCreated')}
                                name="dateCreated"
                                type="date"
                                inputRef={register({
                                    setValueAs: (value) => value === "" ? null : value,
                                })}
                                size="small"
                                fullWidth
                                error={!!errors.dateCreated}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={errors.dateCreated && errors.dateCreated.message}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                disabled
                                variant="outlined"
                                label={t('input.price')}
                                name="price"
                                required
                                fullWidth
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }, maxLength: {
                                        value: 200,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }
                                })}
                                placeholder={t('input.price')}
                                size="small"
                                error={!!errors.price}
                                helperText={!!errors.price && errors.price.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                disabled
                                variant="outlined"
                                label={t('input.userObservation')}
                                name="userObservation"
                                required
                                rows={5}
                                multiline={true}
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '1', to: '500' })
                                    }, maxLength: {
                                        value: 100,
                                        message: t('input.invalid.size', { from: '1', to: '500' })
                                    }
                                })}
                                placeholder={t('input.userObservation')}
                                size="small"
                                fullWidth
                                error={!!errors.userObservation}
                                helperText={!!errors.userObservation && errors.userObservation.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth required variant="outlined" size="small">
                                <InputLabel htmlFor="combo-status">{t('input.status')}</InputLabel>
                                <Controller
                                    control={control}
                                    name="status_id"
                                    defaultValue="null"
                                    as={
                                        <Select
                                            disabled={cannotChange}
                                            value
                                            label={t('input.status')}
                                            id="combo-status">
                                            <MenuItem key={0} value="null">Nenhum</MenuItem>
                                            {comboStatusContent()}
                                        </Select>
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label={t('input.link')}
                                name="link"
                                disabled={cannotChange}
                                inputRef={register({
                                    minLength: {
                                        value: 1,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }, maxLength: {
                                        value: 200,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }
                                })}
                                placeholder={t('input.link')}
                                size="small"
                                fullWidth
                                error={!!errors.link}
                                helperText={!!errors.link && errors.link.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                disabled={cannotChange}
                                variant="outlined"
                                label={t('input.operatorObservation')}
                                name="operatorObservation"
                                rows={5}
                                multiline={true}
                                inputRef={register({
                                    minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '1', to: '500' })
                                    }, maxLength: {
                                        value: 100,
                                        message: t('input.invalid.size', { from: '1', to: '500' })
                                    }
                                })}
                                placeholder={t('input.operatorObservation')}
                                size="small"
                                fullWidth
                                error={!!errors.operatorObservation}
                                helperText={!!errors.operatorObservation && errors.operatorObservation.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                disabled
                                format="yyyy-MM-dd HH:mm:ss"
                                variant="outlined"
                                label={t('input.finishedDate')}
                                name="finishedDate"
                                type="date"
                                inputRef={register({
                                    setValueAs: (value) => value === "" ? null : value,
                                })}
                                size="small"
                                fullWidth
                                error={!!errors.finishedDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={errors.finishedDate && errors.finishedDate.message}
                            />
                        </Grid>

                    </Grid>
                </Paper>

                <Grid container spacing={2} className={classes.buttons}>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={backHandler} >{t('button.back')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Button fullWidth disabled={loading || cannotChange} variant="contained" type="submit" color="primary">{t('button.save')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading || cannotChange} variant="contained" onClick={estornoHandler} color="secondary">{t('button.estorno')}</Button>
                    </Grid>
                </Grid>
            </form>

            <CopyrightFrame />

        </Container>
    );
}

export default MarketTransactionRegistration;
