import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import YouTube from 'react-youtube';
import Analytics from '../base/Analytics';
import Client from '../base/Client';
import Code from '../components/code';
import ErrorDialog from '../components/error-dialog';
import TextTitle from '../components/text-title';
import CopyrightFrame from './frames/CopyrightFrame';

function Blog() {
    const useStyles = makeStyles((theme) => ({
        root: {
        },
        video: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [blog, setBlog] = React.useState(null);
    const { blogId } = useParams();
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const [loadingBlog, setLoadingBlog] = React.useState(false);


    const closeHandler = () => {
        setErrorMsg("");
    }

    const loadContent = useCallback(
        () => {
            if (loadingBlog === false
                && blogId !== undefined) {

                setLoadingBlog(true);

                const client = new Client();

                client.send("get", `/pub/v1/blog/${blogId}`, null)
                    .then(data => {
                        if (data.data !== undefined && data.data !== null && data.status === 200) {
                            setBlog(data.data);
                        }
                    }).catch(error => {
                        setBlog({});
                        setErrorMsg(error.customMsg);
                    });
            }
        },
        [blogId, loadingBlog]
    );

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        loadContent();

    }, [loadContent, loadAnalytics]);

    const contentNotLoad = (row, i) => {
        return (<div>
            <Skeleton variant="rect" width={'100%'} height={118} />
            <Skeleton variant="text" width={'100%'} height={118} />
            <Skeleton variant="rect" width={'100%'} height={118} />
        </div>
        )
    }

    return (<div className={classes.root} >

        <Helmet>
            <title data-react-helmet="true">Blog</title>
            {blog !== null && (<meta name="description" content={blog.title} data-react-helmet="true" />)}
        </Helmet>

        <ErrorDialog onClose={closeHandler} errorMsg={errorMsg} />

        <Container maxWidth="md">

            <input type="hidden" value={blog !== null && blog.tags} />

            {blog == null && contentNotLoad()}

            {blog !== null && blog.title && (
                <TextTitle >{blog.title}</TextTitle>
            )}

            {blog !== null && blog.authorName && (
                <Typography variant='caption' gutterBottom>{blog.authorName} - {blog.dateCreated} </Typography>
            )}

            {blog !== null && blog.youtube && blog.youtube !== "" && (
                <YouTube
                    videoId={blog.youtube}
                    id={'video'}
                    className={classes.video}
                    containerClassName={classes.videoRoot}
                ></YouTube>
            )}

            {blog !== null && blog.content != null && (
                <Code>
                    {blog.content === null ? "" : blog.content}
                </Code>
            )}

        </Container>

        <CopyrightFrame />

    </div >);
}

export default Blog;