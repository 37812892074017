import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { Fragment, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import Client from '../../base/Client';
import ErrorDialog from '../../components/error-dialog';
import IntersectionObserver from "../../components/intersection-observer";
import ScaleBox from "../../components/scale-box";
import TextTitle from '../../components/text-title';

function CategoryFrame(props) {

    const { t } = useTranslation();
    const { categoryId } = useParams();
    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        main: {
            display: 'inline-block',
            padding: theme.spacing(8) + 'px ' + theme.spacing(4) + 'px',
            width: '100%',
            background: theme.palette.background.dark,
        },
        title: {
            display: 'inline-block',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(0),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
            '@media (max-width:780px)': {
                '& h4': {
                    fontSize: "18pt",
                },
            },
        },
        description: {
            display: 'inline-block',
            marginBottom: theme.spacing(0),
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(2),
            width: '100%',
        },
        paper: {
            display: 'flex',
            padding: theme.spacing(2),
            height: theme.spacing(16),
            minWidth: theme.spacing(16),
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.background.selected,
            },
            '&:hover *': {
            },
        },
        a: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            fontSize: '1.0714285714285714rem',
        },
        p: {
            marginBottom: theme.spacing(1),
        },
        paperResult: {
            padding: theme.spacing(2),
        },
        logosContainer: {
            display: 'flex',
            margin: 'auto',
        },
        logos: {
            height: '64px',
        },
        backButton: {
            marginTop: theme.spacing(2),
        },
        container: {
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.background.selected,
            },
            '&:hover *': {
            },
        }
    }));

    const classes = useStyles();
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [categories, setCategories] = React.useState(null);
    const [products, setProducts] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const handleClose = () => {
        setErrorMsg("");
    }

    const loadContent = useCallback(
        () => {
            const client = new Client();

            if (products === null && categories === null) {
                setLoading(true);
                if (categoryId !== undefined) {
                    client.send("get", `/pub/v1/product/category/${categoryId}`, null)
                        .then(data => {

                            if (data.data !== undefined) {
                                setProducts(data.data);
                            } else {
                                setProducts(null);
                            }

                        }).catch(error => {
                            setProducts(null);
                            setErrorMsg(error.customMsg);
                        }).finally(() => {
                            setLoading(false);
                        });
                } else {
                    client.send("get", "/pub/v1/product/category/", null)
                        .then(data => {

                            if (data.status === 204) {
                                setCategories([]);
                            } else if (data.data !== undefined) {
                                setCategories(data.data);
                            }

                        }).catch(error => {
                            setErrorMsg(error.customMsg);
                        }).finally(() => {
                            setLoading(false);
                        });
                }
            }
        },
        [categoryId, categories, products],
    );

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    const buildCategories = (itemRow, i) => {
        return (<Grid item xs={12} sm={4} md={2}  key={i}>
            <IntersectionObserver key={i}>
                <ScaleBox delayOrder={i}>
                    <a className={classes.a} href={`/product/category/${itemRow.id}`} >
                        <Paper className={classes.paper} >
                            <span style={{ borderBottom: `2px solid ${itemRow.color}` }}> {itemRow.name}</span>
                        </Paper>
                    </a>
                </ScaleBox>
            </IntersectionObserver>
        </Grid>)
    }

    const buildProducts = (row, i) => {
        return (
            <Grid item xs={12} key={i}>
                <IntersectionObserver key={i}>
                    <ScaleBox delayOrder={i}>
                        <Paper className={classes.paperResult}>
                            <Grid container wrap="nowrap" spacing={2} onClick={() => handlerClick(row.id)} className={classes.container}>
                                <Grid item className={classes.logosContainer}>
                                    <img className={classes.logos} src={row.logo} alt="Logo" ></img>
                                </Grid>

                                <Grid item xs zeroMinWidth>
                                    <Typography color="textSecondary" gutterBottom >
                                        {row.title}
                                    </Typography>

                                    <Typography variant="body2" component="p" gutterBottom>
                                        {row.description}
                                    </Typography>

                                    <Button color="primary" variant="contained" onClick={() => handlerClick(row.id)} size="small">{t('text.learn.more')}</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </ScaleBox>
                </IntersectionObserver>
            </Grid>
        )
    }

    const handlerClick = (productId) => {
        window.location.href = `/product/${productId}`;
    }

    const backHandler = () => {
        history.goBack();
    }

    const contentNotLoad = (row, i) => {
        return (<Grid item xs={12} sm={2} key={i}>
            <Skeleton variant="rect" width={'100%'} height={118} />
        </Grid>)
    }

    const loadAllCategories = () => {
        return (<div className={classes.main}>
            <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

            <div className={classes.title}>
                <Typography variant="h4"> :: {t("text.category.title")}  :: </Typography>
            </div>
            <div className={classes.description}>
                <Typography variant="subtitle1">
                    {categories !== null ?
                        (categories.length > 0 ? t('text.category.description') : t('text.category.empty'))
                        :
                        t('text.category.description')
                    }
                </Typography>
            </div>

            <Grid
                container
                direction="row"
                spacing={2}>

                {loading === false && categories !== null ? (
                    (categories.length > 0 ? categories.map(buildCategories) : "")
                ) : (
                    [...Array(3)].map(contentNotLoad)
                )}

            </Grid>
        </div>);
    }

    const loadOneCategory = () => {
        let tela;

        if (loading === true) {
            tela = [...Array(3)].map(contentNotLoad);

        } else if (products !== null && products.length > 0) {
            tela = (
                <Fragment>
                    <TextTitle gutterBottom> {products[0].category_name}</TextTitle>

                    <Grid
                        container
                        direction="row"
                        spacing={2}>

                        {products.map(buildProducts)}
                    </Grid>
                </Fragment>
            )
        } else {
            tela = <Typography>{t('text.category.no.result')}</Typography>
        }

        return (<Fragment>
            <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

            {tela}

            <Button className={classes.backButton} fullWidth variant="contained" onClick={backHandler} color="primary">{t('button.back')}</Button>

        </Fragment>);
    }
    return (categoryId === undefined ? loadAllCategories() : loadOneCategory());
}

export default CategoryFrame;