import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextTitle from '../components/text-title';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Analytics from '../base/Analytics';
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function HomePerfil() {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginTop: '0px',
        },
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        infos: {
            width: '500px',
        },
        itemsInfos: {
            textAlign: 'right',
        },
    }));

    const classes = useStyles();
    const { t } = useTranslation();
    const [loadAnalytics, setAnalytics] = React.useState(false);

    useEffect(() => {

        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

    }, [loadAnalytics]);

    return (
        <div className={classes.root}>
            <Helmet>
                <title data-react-helmet="true">Home</title>
                <meta name="description" content="Dados do usuário logado" data-react-helmet="true" />
                <link rel="canonical" href={`/home`} data-react-helmet="true" />
            </Helmet>

            <TextTitle>{t('text.welcome')}</TextTitle>

            <Paper className={classes.paper}>
                <Grid container
                    spacing={2}
                    className={classes.infos}
                >
                    <Grid item xs={12} sm={2} className={classes.itemsInfos}>
                        <Typography color="textSecondary" variant="body" gutterBottom >
                            Nome:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={10}>
                        <Typography color="primary" variant="body" gutterBottom>
                            Lucas
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container
                    spacing={2}
                    className={classes.infos}
                >
                    <Grid item xs={12} sm={2} className={classes.itemsInfos}>
                        <Typography color="textSecondary" variant="body" gutterBottom>
                            E-mail:
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={10}>
                        <Typography color="primary" variant="body" gutterBottom>
                            Lucas
                        </Typography>
                    </Grid>

                </Grid>

            </Paper>
        </div >
    );
}

export default HomePerfil;