import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Client from "../../base/Client";
import Storages from "../../base/Storages";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB94GHmwjpWGty-TkXfSiad_JUMYfc_sLE",
    authDomain: "byiorio.firebaseapp.com",
    projectId: "byiorio",
    storageBucket: "byiorio.appspot.com",
    messagingSenderId: "559013804626",
    appId: "1:559013804626:web:4df2446dfb20f26a457d8b",
    measurementId: "G-549J6PBCZ1"
};

// Initialize Firebase
initializeApp(firebaseConfig);
//const firebase =
//const analytics = getAnalytics(firebase);

const messaging = getMessaging();

//Gerando o par de chaves
export const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BD4G1q595iABcl2fEJHKt1SKS2NixSFxl7HiwpmvL9A9KLeyUw2gvrFnvmmYwnu6hujDvXjp-l0MrEk-DgJzXlE" })
        .then((currentToken) => {
            if (currentToken) {

                let data = {};
                data.token = currentToken;
                const storages = new Storages();

                if (storages.getUser()) {
                    //Envia token
                    const client = new Client();
                    client.send("post", "/priv/v1/push/token/", data)
                        .then(data => {
                            // console.log(data);
                        })
                        .catch(error => {
                            console.log(error.customMsg);
                        });

                    // console.log('current token for client: ', currentToken);
                    // Perform any other neccessary action with the token
                }
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
