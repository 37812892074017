import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import React from 'react';
// import { useWindowScroll } from 'react-use';

function HomeCarouselFrame(props) {
    const { t } = useTranslation();

    const [registerLoad, setRegisterLoad] = React.useState(0);

    const useStyles = makeStyles((theme) => ({
        itemHome: {
            height: '100%',
            paddingLeft: theme.spacing(2),
        },
        item1Carousel: {
            height: 'calc(70vh - 20px)',
            backgroundImage: 'url(/assets/img/home/learn_back.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
        },
        item2Carousel: {
            height: 'calc(70vh - 20px)',
            backgroundImage: 'url(/assets/img/home/new_back.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
        },
        item3Carousel: {
            height: 'calc(70vh - 20px)',
            backgroundImage: 'url(/assets/img/home/work_back.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
        }
    }));

    const pageRedirect = () => {
        setRegisterLoad(true);
        window.location.href = "/register";
    }

    const classes = useStyles();

    return (<div className={classes.headHome}>
        <Carousel animation='slide' autoPlay={false}>
            <Box className={classes.item3Carousel}>
                <Grid container spacing={0}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.itemHome}>
                    <Grid item xs={12} sm={9} >
                        <Typography variant="h4" color="textSecondary" noWrap>
                            Registre-se
                        </Typography>
                        <Typography variant="body1" color="textPrimary" noWrap>
                            Tenha acesso a conteúdos exclusivos:
                        </Typography>
                        <Typography variant="body1" color="textPrimary" gutterBottom>
                            Exercícios, códigos e provas.
                        </Typography>
                        <Button disabled={registerLoad} variant="contained" type="button" color="primary" onClick={pageRedirect}>{t('button.register')}</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.item1Carousel}>
                <Grid container spacing={0}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.itemHome}>
                    <Grid item xs={12} sm={9} >
                        <Typography variant="h4" color="textSecondary" noWrap>
                            Conhecimento
                        </Typography>
                        <Typography paragraph>
                            Tem dificuldade em aprender novas <br />
                            tecnologias?
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box className={classes.item2Carousel}>
                <Grid container spacing={0}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.itemHome}>
                    <Grid item xs={12} sm={9} >
                        <Typography variant="h4" color="textSecondary" noWrap>
                            Conteúdo Gratuito
                        </Typography>
                        <Typography paragraph>
                            Gostaria de ter acesso aos conteúdos gratuitos?
                        </Typography>
                    </Grid>
                </Grid>
            </Box>


        </Carousel>
    </div>);
}

export default HomeCarouselFrame;