import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';

function RegisterFinishFrame(props) {

    const { handleSubmit } = useForm();
    const { t } = useTranslation();

    const onSubmit = () => {
        if (props.onSucess != null) {
            props.onSucess();
        }
    };

    return (<Box bgcolor="background.paper" p={2} borderRadius={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">
                        {t('register.finish.title')}
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                        {t('register.finish.body')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <img src="/assets/img/background/caminho.640.jpg" style={{ display: "block", width: "100%" }} alt="byiorio" />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="contained" type="submit" color="primary"> {t('button.finish')}</Button>
                </Grid>
            </Grid>
        </form>
    </Box>)
}

export default RegisterFinishFrame;