import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

function ErrorDialog(props) {
    let open = false;
    const { t } = useTranslation();
    const haveMessage = (props.errorMsg !== "" && props.errorMsg !== null);

    if ((props.open !== undefined && props.open !== null)) {
        open = props.open;
    } else if (haveMessage === true) {
        open = true;
    }

    //checa se ja tem uma janela aberta
    //sim vai perder os erros da outra janela
    if (document.querySelectorAll('[id="byiorio-error-dialog"]').length > 1) {
        open = false;
    }

    const login = () => {
        window.location.href = '/login';
    }

    const msgFilter = (msg) => {
        return msg !== undefined && msg !== null && (msg.startsWith("[401]") || msg.startsWith("[403]"));
    }

    return (<Dialog
        open={open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="byiorio-error-dialog"
    >
        <DialogTitle id="alert-dialog-title">{t('dialog.error.title')}</DialogTitle>

        <DialogContent>
            {props.errorMsg === undefined ? (
                <DialogContentText color="primary" id="alert-dialog-description">{t('dialog.error.nocontent')}</DialogContentText>
            ) : (
                <DialogContentText color="primary" id="alert-dialog-description">{props.errorMsg}</DialogContentText>
            )}
        </DialogContent>
        <DialogActions>
            {msgFilter(props.errorMsg) === false ? (
                <Button onClick={props.onClose} color="primary" autoFocus>
                    {t('button.close')}
                </Button>
            ) : (
                <Button onClick={login} color="primary" autoFocus>
                    {t('button.new.login')}
                </Button>
            )}

        </DialogActions>
    </Dialog>);
}

export default ErrorDialog;