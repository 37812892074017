class Storages {
    // constructor() {
    // }


    setToken(token) {
        if (token === null) {
            localStorage.removeItem("token");
        } else {
            localStorage.setItem("token", token);
        }
    }

    getToken() {
        let token = null;

        if (localStorage.getItem('token') !== undefined) {
            token = localStorage.getItem('token');
        }

        return token;
    }

    setUser(user) {
        if (user === null) {
            localStorage.removeItem("user");
        } else {
            localStorage.setItem("user", JSON.stringify(user));
        }
    }

    getUser() {
        let user = null;

        if (localStorage.getItem('user') !== undefined) {
            user = JSON.parse(localStorage.getItem('user'));
        }

        return user;
    }

    setIntentUrl(url) {
        if (url === null) {
            localStorage.removeItem("urlRedirect");
        } else {
            localStorage.setItem("urlRedirect", url);
        }
    }

    getIntentUrl() {
        let url = null;

        if (localStorage.getItem('urlRedirect') !== undefined
            && localStorage.getItem('urlRedirect') !== 'null') {
            url = localStorage.getItem('urlRedirect');
        }

        return url;
    }

    clean() {
        this.setToken(null);
        this.setUser(null);
    }

}

export default Storages;