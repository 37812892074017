import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import CopyrightFrame from './frames/CopyrightFrame';
// import { useTranslation } from 'react-i18next';
import MarketProductsFrame from './frames/MarketProductsFrame';

function MarketProducts(props) {
    // const { t } = useTranslation();

    const useStyles = makeStyles((theme) => ({
        main: {
            padding: theme.spacing(0) + 'px ' + theme.spacing(0) + 'px',
            maxWidth: "1280px",
            margin: "auto",
        },
        title: {
            display: 'inline-block',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(0),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
            '@media (max-width:780px)': {
                '& h4': {
                    fontSize: "18pt",
                },
            },
        },
        description: {
            display: 'inline-block',
            marginBottom: theme.spacing(0),
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(2),
            width: '100%',
        },
        root: {
            display: "flex",
            paddingBottom: theme.spacing(2),
            boxSizing: 'border-box',
        },
        logos: {
            width: '128px',
            height: '128px',
        },
        cardRoot: {
            display: 'flex',
            flexDirection: 'column',
            height: '100% !important',
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.background.selected,
            },
            '&:hover *': {
            },
        },
        cardContent: {
            flex: '50%',
            verticalAlign: 'top',
            paddingBottom: '0px',
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        cardLogo: {
            background: '#403f3f',
            flex: '40%',
            textAlign: 'center',
            verticalAlign: 'top',
        },
        cardFooter: {
            flex: '10%',
            verticalAlign: 'bottom',
            alignSelf: 'center',
            padding: theme.spacing(2),
            '& button': {
                backgroundColor: '#403f3f',
                paddingBottom: theme.spacing(1),
                paddingTop: theme.spacing(1),
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            }
        },
    }));

    const classes = useStyles();

    return (
        <div className={classes.main}>

            <MarketProductsFrame />

            <CopyrightFrame />
        </div>
    );
}

export default MarketProducts;