import { Button, Container, Grid, Paper, TextField, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Editor } from '@tinymce/tinymce-react';
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import Analytics from '../base/Analytics';
import Client from '../base/Client';
import Utils from '../base/Utils';
import ErrorDialog from '../components/error-dialog';
import QuestionDialog from '../components/question-dialog';
import TextTitle from '../components/text-title';
import CopyrightFrame from './frames/CopyrightFrame';

function ItemRegistration() {
    const ajax = new Client();
    const [exitPage, setExitPage] = React.useState(false);
    const [reloadPage, setReloadPage] = React.useState(false);
    const { chapterId, productId, itemId } = useParams();
    const { register, errors, handleSubmit, setValue, control } = useForm();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [item, setItem] = React.useState(null);
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [loadingItem, setLoadingItem] = React.useState(false);
    const [content, setContent] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const [loadAnalytics, setAnalytics] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        paper: {
            flexGrow: 1,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        row: {
            cursor: "pointer",
            color: '#000',
            '&:hover': {
                background: theme.palette.primary.main,
            },
            "& td": {
                '&:hover': {
                    color: theme.palette.text.disabled,
                },
            },
        },
    }));

    const classes = useStyles();

    const errorCloseHandler = () => {
        setErrorMsg("");

        if (exitPage) {
            backHandler();
        } else if (reloadPage) {
            let reloadLocation = `/registration/product/${productId}/chapter/${chapterId}/item/${item.id}`;
            document.location.href = reloadLocation;
        }
    }

    const deleteNoHandler = () => {
        setDeleteDialog(false);
    }

    const deleteYesHandler = () => {
        setLoading(true);
        ajax.send("delete", `/adm/v1/product/${productId}/chapter/${chapterId}/item/${itemId}`)
            .then(response => {
                if (response.status === 200) {
                    setItem(null);
                    setErrorMsg(t('text.register.deleted'));
                    setExitPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
                setDeleteDialog(false);
            });
    }

    const backHandler = () => {
        document.location.href = `/registration/product/${productId}/chapter/${chapterId}`;
    }

    const deleteHandler = () => {
        setDeleteDialog(true);
    }

    const editorHandler = (e, editor) => {
        setContent(e.target.getContent());
    }

    const onSubmit = (data) => {
        //Colocando content do Edit na chamada
        data.content = content;

        //Arruma o checkbox
        if (data.statusCheck === true) {
            data.active = 1;
        } else {
            data.active = 0;
        }
        data.statusCheck = undefined;

        //Colocando load
        setLoading(true);

        //executa a chamada
        let url = `/adm/v1/product/${productId}/chapter/${chapterId}/item/`;
        let action = "post";

        if (item !== null && item !== undefined) {
            url = `/adm/v1/product/${productId}/chapter/${chapterId}/item/${itemId}`;
            action = "put";
        }

        ajax.send(action, url, data)
            .then(response => {
                setItem(response.data);

                if (response.status === 200) {
                    setErrorMsg(t('text.register.save'));
                    setReloadPage(true);
                } else if (response.status === 201) {
                    setReloadPage(true);
                    setErrorMsg(t('text.register.insert'));
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
            });
    }

    const loadContent = useCallback(
        () => {
            const client = new Client();
            if (item === null && itemId !== undefined) {
                setLoadingItem(true);
                client.send("get", `/adm/v1/product/${productId}/chapter/${chapterId}/item/${itemId}`, null)
                    .then(data => {
                        if (data.data !== undefined && data.data !== null && data.status === 200) {
                            let utils = new Utils();
                            setValue('title', data.data.title);
                            setValue('description', data.data.description);
                            setValue('itemOrder', data.data.itemOrder);
                            setValue('logo', data.data.logo);
                            setValue('youtube', data.data.youtube);
                            setValue('dateCreated', utils.formatDateYYYYDDMM(data.data.dateCreated));
                            setContent(data.data.content);
                            setValue('points', data.data.points);
                            setValue('durationExpectation', data.data.durationExpectation);
                            setValue('tags', data.data.tags);
                            setItem(data.data);

                            if (data.data.active === 1) {
                                setValue("statusCheck", true);
                            } else {
                                setValue("statusCheck", false);
                            }

                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                    }).finally(() => {
                        setLoadingItem(false);
                    });
            }
        },
        [item, itemId, chapterId, productId, setValue],
    );

    useEffect(() => {

        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        if (loadingItem === false && !errorMsg) {
            loadContent();
        }

    }, [loadContent, loadingItem, loadAnalytics, errorMsg]);

    return (<Container maxWidth="md" >
        <Helmet>
            <title data-react-helmet="true">Cadastrar Item</title>
            <meta name="description" content="Cadastrar itens para um capítulo" data-react-helmet="true" />
            <link rel="canonical" href={`/registration/product/${productId}/chapter/${chapterId}/item/${itemId}`} data-react-helmet="true" />
        </Helmet>

        <QuestionDialog open={deleteDialog} yesHandler={deleteYesHandler} noHandler={deleteNoHandler} message={t('dialog.delete.content')} />

        <ErrorDialog onClose={errorCloseHandler} errorMsg={errorMsg} />

        <TextTitle>{t('text.chapter.register')}</TextTitle>

        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            variant="outlined"
                            label={t('input.title')}
                            name="title"
                            required
                            fullWidth
                            inputRef={register({
                                required: t('input.required')
                                , minLength: {
                                    value: 2,
                                    message: t('input.invalid.size', { from: '2', to: '200' })
                                }, maxLength: {
                                    value: 200,
                                    message: t('input.invalid.size', { from: '2', to: '200' })
                                }
                            })}
                            placeholder={t('input.title')}
                            size="small"
                            error={!!errors.title}
                            helperText={!!errors.title && errors.title.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            variant="outlined"
                            label={t('input.description')}
                            name="description"
                            required
                            fullWidth
                            inputRef={register({
                                required: t('input.required')
                                , minLength: {
                                    value: 2,
                                    message: t('input.invalid.size', { from: '2', to: '200' })
                                }, maxLength: {
                                    value: 200,
                                    message: t('input.invalid.size', { from: '2', to: '200' })
                                }
                            })}
                            placeholder={t('input.description')}
                            size="small"
                            error={!!errors.description}
                            helperText={!!errors.description && errors.description.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            variant="outlined"
                            label={t('input.order')}
                            name="itemOrder"
                            required
                            fullWidth
                            inputRef={register({
                                required: t('input.required')
                                , minLength: {
                                    value: 1,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }, maxLength: {
                                    value: 200,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }
                            })}
                            placeholder={t('input.order')}
                            size="small"
                            error={!!errors.itemOrder}
                            helperText={!!errors.itemOrder && errors.itemOrder.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={t('input.logo')}
                            name="logo"
                            required
                            inputRef={register({
                                required: t('input.required')
                                , minLength: {
                                    value: 1,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }, maxLength: {
                                    value: 200,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }
                            })}
                            placeholder={t('input.logo')}
                            size="small"
                            fullWidth
                            error={!!errors.logo}
                            helperText={!!errors.logo && errors.logo.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={t('input.youtube')}
                            name="youtube"
                            inputRef={register({
                                minLength: {
                                    value: 1,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }, maxLength: {
                                    value: 200,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }
                            })}
                            placeholder={t('input.youtube')}
                            size="small"
                            fullWidth
                            error={!!errors.youtube}
                            helperText={!!errors.youtube && errors.youtube.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={t('input.points')}
                            name="points"
                            inputRef={register({
                                minLength: {
                                    value: 1,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }, maxLength: {
                                    value: 200,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }
                            })}
                            placeholder={t('input.points')}
                            size="small"
                            fullWidth
                            error={!!errors.points}
                            helperText={!!errors.points && errors.points.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={t('input.durationExpectation')}
                            name="durationExpectation"
                            inputRef={register({
                                minLength: {
                                    value: 1,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }, maxLength: {
                                    value: 200,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }
                            })}
                            placeholder={t('input.durationExpectation')}
                            size="small"
                            fullWidth
                            error={!!errors.durationExpectation}
                            helperText={!!errors.durationExpectation && errors.durationExpectation.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    {itemId !== undefined && <Grid item xs={12}>
                        <TextField
                            disabled
                            format="yyyy-MM-dd"
                            variant="outlined"
                            label={t('input.dateCreated')}
                            name="dateCreated"
                            type="date"
                            inputRef={register({
                                setValueAs: (value) => value === "" ? null : value,
                            })}
                            size="small"
                            fullWidth
                            error={!!errors.dateCreated}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={errors.dateCreated && errors.dateCreated.message}
                        />
                    </Grid>}

                    <Grid item xs={12}>
                        <FormControlLabel
                            label={t('input.activated')}
                            control={
                                <Controller
                                    name="statusCheck"
                                    control={control}
                                    defaultValue={false}
                                    render={({ onChange, value }) => (
                                        < Checkbox
                                            onChange={e => onChange(e.target.checked)}
                                            checked={value}
                                        />
                                    )}
                                />
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={t('input.tags')}
                            name="tags"
                            required
                            inputRef={register({
                                required: t('input.required')
                                , minLength: {
                                    value: 2,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }, maxLength: {
                                    value: 100,
                                    message: t('input.invalid.size', { from: '1', to: '200' })
                                }
                            })}
                            placeholder={t('input.tags')}
                            size="small"
                            fullWidth
                            error={!!errors.tags}
                            helperText={!!errors.tags && errors.tags.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <Typography>{t('text.content')}</Typography>
                        <Editor
                            // className={classes.editorStyle}
                            onChange={editorHandler}
                            apiKey="3obog2tfr8d2fkp772lwgqqv2pu6yykn0smp22xg4xystnuy"
                            init={{
                                //content_style: 'body {  height:500px }',
                                //body_class: classes.editorStyle,
                                //advcode casechange checklist export pageembed  advtable powerpaste formatpainter linkchecker a11ychecker permanentpen mediaembed tinymcespellchecker
                                height: "500",
                                skin: 'naked',
                                selector: "#conteudo",
                                forced_root_block: false,
                                plugins: 'codesample  autolink lists media table link image',
                                toolbar: 'undo redo | bold italic link | alignleft aligncenter alignright | code | codesample | image',
                                contextmenu: 'link image table'
                            }}
                            initialValue={item === null ? "" : item.content}
                        />
                        {/* 
                            <Code>
                                {contentHTML === null ? "" : contentHTML}
                            </Code> */}
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.buttons}>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={backHandler} >{t('button.back')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.save')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={deleteHandler} color="secondary">{t('button.delete')}</Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>

        <CopyrightFrame />
    </Container>);
}

export default ItemRegistration;
