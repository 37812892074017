
import CopyrightFrame from './frames/CopyrightFrame';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import React, { useEffect, useCallback } from "react";
import Client from '../base/Client';
import Utils from '../base/Utils';
import { TextField, Grid, Button, Container, Paper, MenuItem, InputLabel, Select, FormControl, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { Editor } from '@tinymce/tinymce-react';
import ErrorDialog from '../components/error-dialog';
import QuestionDialog from '../components/question-dialog';
import TextTitle from '../components/text-title';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Analytics from '../base/Analytics';
import { Helmet } from "react-helmet";
// import Code from '../components/code';

function ProductRegistration() {

    const useStyles = makeStyles((theme) => ({
        paper: {
            flexGrow: 1,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        buttons: {
            marginTop: theme.spacing(2),
        },
        row: {
            cursor: "pointer",
            color: '#000',
            '&:hover': {
                background: theme.palette.primary.main,
            },
            "& td": {
                '&:hover': {
                    color: theme.palette.text.disabled,
                },
            },
        },
    }));

    const classes = useStyles();
    const { productId } = useParams();

    const [product, setProduct] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    const [category, setCategory] = React.useState(null);
    const [content, setContent] = React.useState(null);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [loadingProduct, setLoadingProduct] = React.useState(false);
    const [loadingStatus, setLoadingStatus] = React.useState(false);
    const [loadingCategory, setLoadingCategory] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const { register, errors, handleSubmit, setValue, control } = useForm();
    const [exitPage, setExitPage] = React.useState(false);
    const [reloadPage, setReloadPage] = React.useState(false);
    const ajax = new Client();
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const { t } = useTranslation();

    const itemInsertHandler = () => {
        document.location.href = `/registration/product/${productId}/chapter/`;
    }

    const itemDetailHandler = (id) => {
        document.location.href = `/registration/product/${productId}/chapter/${id}`;
    }

    const closeHandler = () => {
        setErrorMsg("");

        if (exitPage) {
            backHandler();
        } else if (reloadPage) {
            let reloadLocation = `/registration/product/${product.id}`;
            document.location.href = reloadLocation;
        }
    }

    const deleteNoHandler = () => {
        setDeleteDialog(false);
    }

    const deleteYesHandler = () => {
        setLoading(true);

        ajax.send("delete", `/adm/v1/product/${product.id}`)
            .then(response => {
                if (response.status === 200) {
                    setProduct(null);
                    setErrorMsg(t('text.register.deleted'));
                    setExitPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
                setDeleteDialog(false);
            });
    }

    const backHandler = () => {
        document.location.href = "/registration/product/search";
    }

    const editorHandler = (e, editor) => {
        setContent(e.target.getContent());
    }

    const deleteHAndler = () => {
        setDeleteDialog(true);
    }

    const onSubmit = (data) => {
        //Colocando content do Edit na chamada
        data.content = content;

        //Arruma o checkbox
        if (data.privyCheck === true) {
            data.privy = 1;
        } else {
            data.privy = 0;
        }
        data.privyCheck = undefined;

        //Colocando load
        setLoading(true);

        //executa a chamada
        let url = "/adm/v1/product/";
        let action = "post";

        if (product !== null && product !== undefined) {
            url = `/adm/v1/product/${product.id}`;
            action = "put";
        }

        ajax.send(action, url, data)
            .then(response => {
                setProduct(response.data);

                if (response.status === 200) {
                    setErrorMsg(t('text.register.save'));
                } else if (response.status === 201) {
                    setErrorMsg(t('text.register.insert'));
                    setReloadPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
            });
    }

    const comboStatusContent = () => {
        return (status !== null && status.map((row, index) => {
            return <MenuItem key={index} value={row.id} >{row.name}</MenuItem>;
        })
        )
    }

    const gridChapters = () => {
        return (
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TableContainer component={Paper} className={classes.table} >
                            <Table aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={{ width: 100 }}>Id</TableCell>
                                        <TableCell align="left">Assunto</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {product != null && product.productChapters !== null && product.productChapters.map((chapter, index) => (
                                        <TableRow key={index} className={classes.row} onClick={() => itemDetailHandler(chapter.id)}>
                                            <TableCell align="left">{chapter.id}</TableCell>
                                            <TableCell align="left">{chapter.title}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} >
                        <Button disabled={loading} variant="contained" onClick={itemInsertHandler} >{t('button.insert')}</Button>
                    </Grid>
                </Grid>
            </Paper>);
    }

    const comboCategoryContent = () => {
        return (category !== null && category.map((row, index) => {
            return <MenuItem key={index} value={row.id} >{row.name}</MenuItem>;
        })
        )
    }

    const loadCategory = useCallback(() => {
        const client = new Client();
        if (status === null) {
            setLoadingCategory(true);
            client.send("get", "/adm/v1/product/category/", null)
                .then(data => {
                    if (data.data !== undefined && data.data !== null && data.status === 200) {
                        setCategory(data.data);
                    }
                }).catch(error => {
                    setCategory([]);
                    setErrorMsg(error.customMsg);
                }).finally(() => {
                    setLoadingCategory(false);
                });
        }
    }, [status]);

    const loadStatus = useCallback(() => {
        const client = new Client();
        if (status === null) {
            setLoadingStatus(true);

            client.send("get", "/adm/v1/product/status/", null)
                .then(data => {
                    if (data.data !== undefined && data.data !== null && data.status === 200) {
                        setStatus(data.data);
                    }
                }).catch(error => {
                    setErrorMsg(error.customMsg);
                    setStatus([]);
                }).finally(() => {
                    setLoadingStatus(false);
                });
        }
    }, [status]);

    const loadContent = useCallback(
        () => {
            const client = new Client();
            if (product === null && productId !== undefined) {

                setLoadingProduct(true);

                client.send("get", `/adm/v1/product/${productId}`, null)
                    .then(data => {
                        if (data.data !== undefined && data.data !== null && data.status === 200) {
                            let utils = new Utils();
                            setValue('title', data.data.title);
                            setValue('description', data.data.description);
                            setValue('logo', data.data.logo);
                            setValue('dateCreated', utils.formatDateYYYYDDMM(data.data.dateCreated));
                            setValue("status_id", data.data.status.id);
                            setValue("category_id", data.data.category.id);
                            setValue("tags", data.data.tags);
                            setValue('youtube', data.data.youtube);
                            setContent(data.data.content);

                            //Arruma o checkbox
                            if (data.data.privy === 1) {
                                setValue("privyCheck", true);
                            } else {
                                setValue("privyCheck", false);
                            }

                            setProduct(data.data);
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                    }).finally(() => {
                        setLoadingProduct(false);
                    });
            }
        },
        [product, productId, setValue],
    );

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        if (!errorMsg) {
            if (loadingStatus === false) {
                loadStatus();
            }

            if (loadingCategory === false) {
                loadCategory();
            }

            if (loadingProduct === false) {
                loadContent();
            }
        }
    }, [loadStatus, loadCategory, loadContent, loadingProduct, loadingStatus, loadingCategory, loadAnalytics, errorMsg]);

    return (
        <Container maxWidth="md" >

            <Helmet>
                <title data-react-helmet="true">Cadastrar Assunto</title>
                <meta name="description" content="Cadastro de novos assuntos" data-react-helmet="true" />
                <link rel="canonical" href={`/registration/product/${productId}`} data-react-helmet="true" />
            </Helmet>

            <QuestionDialog open={deleteDialog} yesHandler={deleteYesHandler} noHandler={deleteNoHandler} message={t('dialog.delete.content')} />
            <ErrorDialog onClose={closeHandler} errorMsg={errorMsg} />

            <TextTitle>{t('text.product.register')}</TextTitle>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextField
                                variant="outlined"
                                label={t('input.title')}
                                name="title"
                                required
                                fullWidth
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }, maxLength: {
                                        value: 200,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }
                                })}
                                placeholder={t('input.title')}
                                size="small"
                                error={!!errors.title}
                                helperText={!!errors.title && errors.title.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                variant="outlined"
                                label={t('input.description')}
                                name="description"
                                required
                                fullWidth
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }, maxLength: {
                                        value: 200,
                                        message: t('input.invalid.size', { from: '2', to: '200' })
                                    }
                                })}
                                placeholder={t('input.description')}
                                size="small"
                                error={!!errors.description}
                                helperText={!!errors.description && errors.description.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label={t('input.logo')}
                                name="logo"
                                required
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '2', to: '100' })
                                    }, maxLength: {
                                        value: 100,
                                        message: t('input.invalid.size', { from: '2', to: '100' })
                                    }
                                })}
                                placeholder={t('input.logo')}
                                size="small"
                                fullWidth
                                error={!!errors.logo}
                                helperText={!!errors.logo && errors.logo.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth required variant="outlined" size="small">
                                <InputLabel htmlFor="combo-status">{t('input.status')}</InputLabel>
                                <Controller
                                    control={control}
                                    name="status_id"
                                    defaultValue="null"
                                    as={
                                        <Select
                                            value
                                            label={t('input.status')}
                                            id="combo-status">
                                            <MenuItem key={0} value="null">Nenhum</MenuItem>
                                            {comboStatusContent()}
                                        </Select>
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth required variant="outlined" size="small">
                                <InputLabel htmlFor="combo-status">{t('input.category')}</InputLabel>
                                <Controller
                                    control={control}
                                    name="category_id"
                                    defaultValue="null"
                                    as={
                                        <Select
                                            value
                                            label={t('input.category')}
                                            id="combo-category">
                                            <MenuItem key={0} value="null">Nenhum</MenuItem>
                                            {comboCategoryContent()}
                                        </Select>
                                    }
                                />
                            </FormControl>
                        </Grid>


                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label={t('input.youtube')}
                                name="youtube"
                                inputRef={register({
                                    minLength: {
                                        value: 1,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }, maxLength: {
                                        value: 200,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }
                                })}
                                placeholder={t('input.youtube')}
                                size="small"
                                fullWidth
                                error={!!errors.youtube}
                                helperText={!!errors.youtube && errors.youtube.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                label={t('input.privy')}
                                control={
                                    <Controller
                                        name="privyCheck"
                                        control={control}
                                        defaultValue={false}
                                        render={({ onChange, value }) => (
                                            < Checkbox
                                                onChange={e => onChange(e.target.checked)}
                                                checked={value}
                                            />
                                        )}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label={t('input.tags')}
                                name="tags"
                                required
                                inputRef={register({
                                    required: t('input.required')
                                    , minLength: {
                                        value: 2,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }, maxLength: {
                                        value: 100,
                                        message: t('input.invalid.size', { from: '1', to: '200' })
                                    }
                                })}
                                placeholder={t('input.tags')}
                                size="small"
                                fullWidth
                                error={!!errors.tags}
                                helperText={!!errors.tags && errors.tags.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <Typography>{t('text.content')}</Typography>
                            <Editor
                                // className={classes.editorStyle}
                                onChange={editorHandler}
                                apiKey="3obog2tfr8d2fkp772lwgqqv2pu6yykn0smp22xg4xystnuy"
                                init={{
                                    //content_style: 'body {  height:500px }',
                                    //body_class: classes.editorStyle,
                                    //advcode casechange checklist export pageembed  advtable powerpaste formatpainter linkchecker a11ychecker permanentpen mediaembed tinymcespellchecker
                                    height: "500",
                                    skin: 'naked',
                                    selector: "#conteudo",
                                    forced_root_block: false,
                                    plugins: 'codesample  autolink lists media table link image',
                                    toolbar: 'undo redo | bold italic link | alignleft aligncenter alignright | code | codesample | image',
                                    contextmenu: 'link image table'
                                }}
                                initialValue={product === null ? "" : product.content}
                            />
                            {/* 
                            <Code>
                                {contentHTML === null ? "" : contentHTML}
                            </Code> */}
                        </Grid>

                        {productId !== undefined && <Grid item xs={12}>
                            <TextField
                                disabled
                                format="yyyy-MM-dd"
                                variant="outlined"
                                label={t('input.dateCreated')}
                                name="dateCreated"
                                type="date"
                                inputRef={register({
                                    setValueAs: (value) => value === "" ? null : value,
                                })}
                                size="small"
                                fullWidth
                                error={!!errors.dateCreated}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={errors.dateCreated && errors.dateCreated.message}
                            />
                        </Grid>}
                    </Grid>
                </Paper>

                <TextTitle>{t('text.chapter.register')}</TextTitle>
                {product === null && <Typography>{t('text.product.save.chapter.warning')}</Typography>}

                {product !== null && gridChapters()}

                <Grid container spacing={2} className={classes.buttons}>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={backHandler} >{t('button.back')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.save')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={deleteHAndler} color="secondary">{t('button.delete')}</Button>
                    </Grid>
                </Grid>
            </form>

            <CopyrightFrame />

        </Container>
    );
}

export default ProductRegistration;
