import Container from '@material-ui/core/Container';
import Analytics from '../base/Analytics';
import React, { useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";

function About() {

    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },

    }));
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }
    }, [loadAnalytics]);

    return (
        <Container maxWidth="sm">
            <Helmet>
                <title data-react-helmet="true">Sobre</title>
                <meta name="description" content="Aqui detalho tecnicamente as atualizações da página" data-react-helmet="true" />
                <link rel="canonical" href="/about" data-react-helmet="true" />
            </Helmet>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6" color="secundary">Backlog</Typography>

                <Typography gutterBottom variant="body1">
                    - Upload de imagens<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6"> (React)- V0.1.9  - 27/02/2023</Typography>
                <Typography gutterBottom variant="body1">
                    - Melhorias de layout<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Backend (SpringBoot) - V0.1.6  e Frontend (React)- V0.1.7  - 29/06/2022</Typography>
                <Typography gutterBottom variant="body1">
                    - Sistema de mercado de pontos<br />
                    - Sistemas de Motor de pontos<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Backend (SpringBoot) - V0.1.6  e Frontend (React)- V0.1.7  - 07/06/2022</Typography>
                <Typography gutterBottom variant="body1">
                    - Melhoria Visual<br />
                    - Correção de pontos<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Backend (SpringBoot) - V0.1.5  e Frontend (React)- V0.1.6  - 12/02/2022</Typography>
                <Typography gutterBottom variant="body1">
                    - Sistema de PUSH por tópicos<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Backend (SpringBoot) - V0.1.4  e Frontend (React)- V0.1.5  - 02/02/2022</Typography>
                <Typography gutterBottom variant="body1">
                    - Ativar ou desativar um capítulo<br />
                    - Ativar ou desativar um item<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Backend (SpringBoot) - V0.1.3 - 01/01/2022</Typography>
                <Typography gutterBottom variant="body1">
                    - Melhoria na segurança<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Frontend (React)- V0.1.4  Backend (SpringBoot) - V0.1.2 - 10/01/2021</Typography>
                <Typography gutterBottom variant="body1">
                    - Adicionando Gerencimento de Blog<br />
                    - Adicionando posts do Blog.<br />
                </Typography>
            </Paper>


            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Frontend (React)- V0.1.3  Backend (SpringBoot) - V0.1.1 - 25/12/2021</Typography>
                <Typography gutterBottom variant="body1">
                    - Arrumando pesquisa<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Frontend (React)- V0.1.2 - 09/12/2021</Typography>
                <Typography gutterBottom variant="body1">
                    - Criando páginas para o SEO bot<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Frontend (React)- V0.1.1 - 05/12/2021</Typography>
                <Typography gutterBottom variant="body1">
                    - Correções visuais<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Frontend (React)- V0.1.0 - 12/11/2021</Typography>
                <Typography gutterBottom variant="body1">
                    - Sistema de login<br />
                    - Sistema de validação de e-mail<br />
                    - Sistema de visualização de conteúdo<br />
                    - Sistema de pontuação de visualização<br />
                    - Sistema de cadastro de usuário<br />
                    - Sistema de cadastro de cursos<br />
                    - Sistema de pesquisa de cursos<br />
                    - Sistema de edição de fontes<br />
                    - Sistema de cursos<br />
                </Typography>
            </Paper>

            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6">Backend (SpringBoot) - V0.1.0 - 12/11/2021</Typography>
                <Typography gutterBottom variant="body1">
                    - Sistema de login<br />
                    - Sistema de validação de e-mail<br />
                    - Sistema de visualização de conteúdo<br />
                    - Sistema de pontuação de visualização<br />
                    - Sistema de cadastro de usuário<br />
                    - Sistema de cadastro de cursos<br />
                    - Sistema de pesquisa de cursos<br />
                    - Sistema de segurança<br />
                    - Modelagem de Banco de Dados v1<br />
                </Typography>
            </Paper>
        </Container>
    );
}

export default About;