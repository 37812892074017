import Container from '@material-ui/core/Container';
import Analytics from '../base/Analytics';
import React, { useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import ErrorIcon from '@material-ui/icons/ErrorTwoTone';

function NotFound() {

    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            textAlign: 'center',
        },
        icon: {
            textAlign: 'center',
            height: '100px',
            width: '100px',
        }
    }));
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }
    }, [loadAnalytics]);

    return (
        <Container maxWidth="sm">
            <Helmet>
                <title data-react-helmet="true">Página não encontrada</title>
                <meta name="description" content="Página não encontrada" data-react-helmet="true" />
            </Helmet>
            <Paper className={classes.paper}>
                <Typography gutterBottom variant="h6" color="secundary">Página não encontrada...</Typography>
                <ErrorIcon className={classes.icon} />
            </Paper>


        </Container>
    );
}

export default NotFound;