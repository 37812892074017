
import { Container, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Analytics from '../base/Analytics';
import Client from '../base/Client';
import ErrorDialog from '../components/error-dialog';
import TextTitle from '../components/text-title';
import CopyrightFrame from './frames/CopyrightFrame';

function MarketTransactionSearch() {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            margin: theme.spacing(2),
            marginTop: '0px',
        },
        table: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        searchGrid: {
            marginBottom: theme.spacing(2),
        },
        row: {
            cursor: "pointer",
            color: '#000',
            '&:hover': {
                background: theme.palette.primary.main,
            },
            "& td": {
                '&:hover': {
                    color: theme.palette.text.disabled,
                },
            },
        }

    }));

    const classes = useStyles();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [market, setMarket] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const { handleSubmit, control, setValue } = useForm();
    const ajax = new Client();
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const [status, setStatus] = React.useState(null);
    const [loadingStatus, setLoadingStatus] = React.useState(false);

    const comboStatusContent = () => {
        return (status !== null && status.map((row, index) => {
            return <MenuItem key={index} value={row.code} >{row.description}</MenuItem>;
        })
        )
    }

    const handleClose = () => {
        setErrorMsg("");
    }

    const handlerClearClick = () => {
        setMarket(null);
        loadContent();
    }

    const loadStatus = useCallback(() => {
        if (status === null) {
            setLoadingStatus(true);
            const client = new Client();
            client.send("get", `/pub/v1/market/transaction/status`, null)
                .then(data => {
                    if (data.data !== undefined && data.data !== null && data.status === 200) {
                        setStatus(data.data);
                    }
                }).catch(error => {
                    setErrorMsg(error.customMsg);
                }).finally(() => {
                    setLoadingStatus(false);
                });
        }
    }, [status]);

    const onSubmit = (data) => {
        //Colocando load
        setLoading(true);

        //Corrige valor
        if (data.status_id === "null") {
            data.status_id = "";
        }

        //executa a chamada
        ajax.send("get", `/adm/v1/market/transaction/?idStatus=${data.status_id}`)
            .then(response => {
                if (response.data !== undefined && response.data !== "") {
                    setMarket(response.data);
                } else {
                    setMarket([]);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
                setMarket([]);
            }).finally(() => {
                setLoading(false);
            });
    };

    const handlerProductClick = (id) => {
        document.location.href = `/registgration/market/transactions/${id}`;
    }

    const loadContent = useCallback(
        () => {
            const client = new Client();

            //Roda conteudo
            if (market === null) {
                setLoading(true);

                //Coloca o combo como pendente
                setValue("status_id", "1");

                client.send("get", "/adm/v1/market/transaction/?idStatus=1", null)
                    .then(data => {
                        if (data.status === 200) {
                            setMarket(data.data);
                        } else {
                            setMarket("");
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                        setMarket([]);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        },
        [market, setValue],
    );

    useEffect(() => {

        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        if (loading === false) {
            loadContent();
        }

        if (loadingStatus === false) {
            loadStatus();
        }

    }, [loadContent, loading, loadAnalytics, loadStatus, loadingStatus]);

    return (
        <Container maxWidth="md" >
            <Helmet>
                <title data-react-helmet="true">Pesquisar Produtos</title>
                <meta name="description" content="Pesquisar transacoes cadastradas" data-react-helmet="true" />
                <link rel="canonical" href={`/registgration/market/transactions/search`} data-react-helmet="true" />
            </Helmet>

            <div className={classes.root}>
                <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

                <TextTitle>{t('text.title.market.products')}</TextTitle>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} className={classes.searchGrid}>
                        <Grid item xs={12}>
                            <FormControl fullWidth required variant="outlined" size="small">
                                <InputLabel htmlFor="combo-status">{t('input.status')}</InputLabel>
                                <Controller
                                    control={control}
                                    name="status_id"
                                    defaultValue="null"
                                    as={
                                        <Select
                                            value
                                            label={t('input.status')}
                                            id="combo-status">
                                            <MenuItem key={0} value="null">Nenhum</MenuItem>
                                            {comboStatusContent()}
                                        </Select>
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.search')}</Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button fullWidth disabled={loading} variant="contained" type="reset" color="primary" onClick={() => handlerClearClick()}>{t('button.clear')}</Button>
                        </Grid>
                    </Grid>
                </form>

                <TableContainer component={Paper} className={classes.table} >
                    <Table aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{ width: 100 }}>Id</TableCell>
                                <TableCell align="left">Produto</TableCell>
                                <TableCell align="left">Descrição</TableCell>
                                <TableCell align="left">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {market !== null && market !== "" && market.map((product, index) => (
                                <TableRow key={index} className={classes.row} onClick={() => handlerProductClick(product.id)}>
                                    <TableCell align="left">{product.id}</TableCell>
                                    <TableCell align="left">{product.marketProduct.title}</TableCell>
                                    <TableCell align="left">{product.userObservation}</TableCell>
                                    <TableCell align="left">{product.statusDescription}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <CopyrightFrame />
            </div>
        </Container>
    );
}

export default MarketTransactionSearch;