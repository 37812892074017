export const Env = {
    ambiente: "producao",
    local: {
        servidor: "http://localhost:8080"
    },
    producao: {
        servidor: "https://api.byiorio.com.br"
    }
};

//process.env.PUBLIC_URL