import React from "react";
import { Route, Redirect } from "react-router-dom";
import Storages from '../../base/Storages';

function PrivateRoute(props) {
    const storages = new Storages();
    const token = storages.getUser(); // localStorage.getItem('token');

    let condition = false;
    
    if (token !== null && token !== undefined) {
        condition = true;
    }else{
        storages.setIntentUrl(props.location.pathname);
    }

    return condition ? (
        <Route path={props.path} exact={props.exact} component={props.component} />) :
        (<Redirect to="/login" />);
};

export default PrivateRoute;