
import MuiMobileStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import ErrorDialog from '../components/error-dialog';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ForgotPasswordFormFrame from './frames/ForgotPasswordFormFrame';
import ForgotPasswordValidationFrame from './frames/ForgotPasswordValidationFrame';
import ForgotPasswordFinishFrame from './frames/ForgotPasswordFinishFrame';
import Analytics from '../base/Analytics';
import { Helmet } from "react-helmet";

function ForgotPassword(props) {
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [emailToValidate, setEmailToValidate] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");
    const { t } = useTranslation();

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundImage: 'url(/assets/img/background/writing-1149962_1920.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: 'calc(100vh - 100px)'
        },
    }));

    const classes = useStyles();

    const nextStep = (data) => {
        if (data && data.config && data.config.data) {
            let res = JSON.parse(data.config.data);
            setEmailToValidate(res.email);
        }

        setActiveStep(activeStep => activeStep + 1);
    };

    const showError = (error) => {
        setErrorMsg(error.customMsg);
    }

    const handleClose = () => {
        setErrorMsg("");
    }

    const goToHome = () => {
        props.history.push('/login');
    };

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

    }, [loadAnalytics]);

    return (
        <div className={classes.root}>
            <Helmet>
                <title data-react-helmet="true">Esqueci minha senha</title>
                <meta name="description" content="Alterar senha" data-react-helmet="true" />
                <link rel="canonical" href={`/forgotPassword`} data-react-helmet="true" />
            </Helmet>

            <CssBaseline />
            <Container maxWidth="sm" >
                <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

                <MuiMobileStepper activeStep={activeStep} orientation="horizontal" >
                    <Step key={0} >
                        <StepLabel>{t('step.reset')}</StepLabel>
                    </Step>

                    <Step key={1} >
                        <StepLabel>{t('step.validate')}</StepLabel>
                    </Step>

                    <Step key={2} >
                        <StepLabel>{t('step.finish')}</StepLabel>
                    </Step>
                </MuiMobileStepper>

                {(() => {
                    switch (activeStep) {
                        case 0: return <ForgotPasswordFormFrame onSucess={nextStep} onError={showError} />
                        case 1: return <ForgotPasswordValidationFrame onSucess={nextStep} email={emailToValidate} onError={showError} />
                        case 2: return <ForgotPasswordFinishFrame onSucess={goToHome} onError={showError} />
                        default: return <ForgotPasswordFormFrame onSucess={nextStep} />
                    }
                })()}

            </Container >
        </div>
    );
}

export default ForgotPassword;