import { createTheme } from "@material-ui/core/styles";
import consoleOTF from '../resources/fonts/InconsolataZi4varlvarquRegular-42gD.otf';

const consoleFont = {
    fontFamily: 'console',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontDisplay: 'swap',
    src: `
    local('Inconsolatazi4varl_qu'),
    local('Inconsolatazi4varl_qu'),
    url(${consoleOTF}) format('opentype')
  `
};

export const theme = createTheme({
    typography: {
        "fontFamily": `"console", "Helvetica"`, //, "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 15,
        "fontWeightLight": 300,
        "fontWeightRegular": 'normal',
        "fontWeightMedium": 500,
    },
    palette: {
        primary: {
            light: '#b2b200',
            main: '#ece895',
            checked: '#00ff00',
            dark: '#d4c570',
            contrastText: '#000',
        },
        text: {
            primary: "#fff",
            secondary: "#ece895",
            dark: '#303030',
            disabled: "#303030",
            hint: "#ece895",
        },
        background: {
            paper: "#303030",
            default: "#000",
            selected: "#363636",
            dark: "#181818",
            light: "radial-gradient(circle, rgba(48,48,48,1) 0%, rgba(33,33,33,1) 100%, rgba(48,48,48,1) 100%)",
        }
    },
    overrides: {
        MuiSelect: {
            icon: {
                color: "#ece895",
            },
            // '&.Mui-selected': {
            //   backgroundColor: "darkgray",
            // }
        },
        MuiInputBase: {
            root: {
                '&.Mui-disabled': {
                    color: "darkgray",
                }
            }
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': [consoleFont],
                // '.Mui-disabled': { color: 'red' }
                body: {
                    // background: "url(/assets/img/background.png)",
                }
            },
        },
        MuiButton: {
            containedPrimary: {
                background: '#ece895', //'linear-gradient(45deg, #ece895 30%, #FF8E53 90%)'
                boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            }
        },
        // MuiTypography: {
        //   colorTextPrimary: {
        //     color: "#ece895"
        //   } 
        //   } 
        //   } 
        // },
        // MuiGrid: {
        //   spacing: {
        //     xs: {
        //       '2': {
        //         margin: "0",
        //         marginLef: "-8",
        //       }
        //     }
        //   }
        // },
        MuiDivider: {
            root: {
                backgroundColor: '#ece895'
            }
        },
        MuiAppBar: {
            root: {
                transition: 'height 150ms ease',
            }
        },
        MuiTypography: {
            paragraph: {
                wordWrap: "break-word"
            },
            colorPrimary: {
                color: '#fff'
            },
            h6: {
                color: '#ece895'
            }
        },
        MuiFormLabel: {
            root: {
                color: "fff",
            },
        },
        MuiInputLabel: {
            root: {
                '&.Mui-disabled': {
                    color: "darkgray",
                }
            }
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: "#787200",
                }
            },
            button: {
                '&:hover': {
                    backgroundColor: "#b9b67740",
                }
            }
        },
        MuiStepLabel: {
            label: {
                color: "#fff",
            },
            active: {
                color: "#ece895 !important",
            }
        },
        MuiStepper: {
            root: {
                padding: "24px 0px",
                backgroundColor: "transparent", //c7c5c5
            }
        },
        MuiStepIcon: {
            root: {
                color: "#d1cbcb",
            }
        },
        MuiOutlinedInput: {
            root: {
                color: "#fff",
            },
            notchedOutline: {
                borderColor: "#ece895"
            }

        },
    },
});