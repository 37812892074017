
import Container from '@material-ui/core/Container';
import Analytics from '../base/Analytics';
import React, { useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";

function TermsOfUse(props) {

    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },

    }));
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }
    }, [loadAnalytics]);

    return (
        <Container maxWidth="md">
            <Helmet>
                <title data-react-helmet="true">Termos de Uso</title>
                <meta name="description" content="Termos de uso" data-react-helmet="true" />
                <link rel="canonical" href="/terms" data-react-helmet="true" />
            </Helmet>

            <Paper className={classes.paper}>
                <Typography paragraph variant="h6" color="secundary">1. OBJETO</Typography>

                <Typography paragraph variant="p">
                    1.1. Os presentes termos de uso têm por objeto a prestação de serviços educacionais, pela BYIORIO ao ALUNO,
                    relativamente ao curso livre, na modalidade a distância (online), cuja carga horária, programação e conteúdo
                    será disponibilizado no site www.byiorio.com.br (“site”), na página do curso escolhido pelo ALUNO (“Curso”).
                </Typography>

                <Typography paragraph variant="p">
                    1.2. O ALUNO se obriga a observar as disposições dos presentes termos de uso e todas as demais normas e procedimentos
                    previstos na ficha técnica do curso, que passa a integrar o presente instrumento.
                </Typography>

                <Typography paragraph variant="p">
                    1.3. O ALUNO tem ciência de que para poder usufruir dos serviços ora contratados, precisará ter acesso a um dispositivo
                    com conexão à Internet, cujos requisitos mínimos de configuração exigidos para a visualização das aulas via internet
                    encontram-se descritos no site.
                </Typography>

                <Typography paragraph variant="p">
                    1.4. Para a efetivação da matrícula, o ALUNO deverá realizar o cadastro completo no site, contendo os seus dados pessoais
                    e/ou outros exigidos pela BYIORIO, bem como deverá realizar o pagamento do Curso na forma e prazos estabelecidos.
                </Typography>

                <Typography paragraph variant="p">
                    1.5. Efetivada a matrícula, a BYIORIO disponibilizará ao ALUNO acesso ao ambiente virtual do Curso, cujo acesso será
                    realizado através de login e senha, os quais serão de uso pessoal e intransferível, sendo vedada a cessão a terceiros, '
                    a qualquer título. A senha garantirá acesso somente ao conteúdo e no prazo de duração do Curso contratado, sendo bloqueada
                    imediatamente após expirado o prazo previsto para duração do Curso.
                </Typography>

                <Typography paragraph variant="p">
                    1.6. O ALUNO concorda e declara-se ciente de que a efetivação da matrícula significará integral concordância com os termos e
                    condições destes termos de uso, à ficha técnica do curso e aos requisitos mínimos de configuração do computador,
                    exigidos para a visualização das aulas.
                </Typography>

                <Typography paragraph variant="h6" color="secundary">2. VIGÊNCIA</Typography>

                <Typography paragraph variant="p">
                    2.1. Os presentes termos de uso terão início com a efetivação da matrícula do ALUNO, nos termos deste instrumento,
                    com término da vigência quando do encerramento previsto para o curso contratado e o pagamento integral de todas as parcelas
                    relativas ao serviço contratado.
                </Typography>

                <Typography paragraph variant="h6" color="secundary">3. PAGAMENTO</Typography>

                <Typography paragraph variant="p">
                    3.1. Pela prestação dos serviços educacionais objeto destes termos de uso, o ALUNO pagará à BYIORIO o valor total do curso, nos
                    valores e vencimentos indicados no site, conforme opção de parcelamento acordada com o ALUNO.
                </Typography>

                <Typography paragraph variant="p">
                    3.1.1. O pagamento poderá ser feito através de cartão de crédito, boleto bancário, PIX, transferência bancária, PayPal e,
                    em alguns cursos, por transferência de criptoativos para a carteira virtual da BYIORIO.
                </Typography>

                <Typography paragraph variant="p">
                    3.1.2. Nas ocasiões em que o pagamento do curso ocorrer através da transferência de criptoativos para a carteira virtual da CURSOLOGY, a cotação vigente para o pagamento será aquela do momento em que o ALUNO concretizar a compra, isto é, no momento em que selecionar a opção de compra do curso.
                    a) Os pagamentos realizados por meio de transferência de criptoativos deverão corresponder ao valor do curso em reais no instante da transferência. A valorização ou desvalorização do criptoativo após realização de transferência não dá a qualquer das partes o direito à ressarcimentos.
                    b) Os pagamentos realizados por meio de transferência de criptoativos deverão ser feitos dentro do período de 15 minutos contatos do instante em que se concluiu pela contratação do curso, sob pena de posterior extorno do valor em criptoativos transferidos e inviabilização desta forma de pagamento, sendo a partir de então permitidas somente as demais formas de pagamento elencadas”.
                    3.2. O ALUNO tem ciência de que o atraso no pagamento de qualquer parcela ou obrigação de pagamento decorrente destes termos de uso permitirá à CURSOLOGY:
                    a) inscrever o devedor em cadastro ou serviços de proteção ao crédito, após a regular notificação prevista na legislação consumerista, a qual será de responsabilidade do cadastro ou serviço de proteção ao crédito;
                    b) promover a cobrança, a execução judicial da dívida ou qualquer outro meio, judicial ou extrajudicial, para recebimento do débito, cujas custas processuais e honorários advocatícios de 20% (vinte por cento) sobre o valor do débito serão assumidos pelo devedor, havendo pagamento extrajudicial ou judicial;
                    c)  promover, em razão do inadimplemento, a resolução dos presentes termos de uso, de imediato e de pleno direito, com, inclusive, corte do acesso ao ambiente virtual do curso, sem prejuízo das medidas para satisfação da prestação não cumprida, sempre observada a legislação de proteção ao consumidor e Lei Federal n° 9.870/1999;
                    d) cobrar o valor em atraso, acrescido de multa moratória de 2% (dois por cento) sobre o valor do débito em aberto;
                    e) acrescer juros moratórios de 1% (um por cento) ao mês, calculados proporcionalmente aos dias corridos (pro rata die), até a data do efetivo pagamento;
                    f) bloquear imediatamente a senha de acesso ao curso.
                </Typography>

                <Typography paragraph variant="p">
                    3.3. O ALUNO tem ciência de que a falta de acesso ao ambiente virtual ou às aulas do curso, não o exonera da obrigação de honrar com o pagamento das parcelas do curso contratado, não assistindo ao ALUNO o direito a qualquer ressarcimento pelas aulas não assistidas.
                    3.4. Qualquer desconto eventualmente concedido pela CURSOLOGY ao ALUNO, não constituirá compromisso ou obrigação para concessão de descontos em outros cursos que o ALUNO pretenda se matricular.
                    4. DA RESOLUÇÃO DO TERMOS DE USO
                    4.1. Sem prejuízo das demais disposições previstas neste termos de uso, a CURSOLOGY poderá rescindir imediatamente o presente instrumento caso o ALUNO esteja inadimplente com qualquer de suas obrigações financeiras relacionadas a este termos de uso, bem como na hipótese de uso indevido, e/ou reprodução e/ou cessão dos materiais ou conteúdos das aulas disponibilizadas para realização do curso, a qualquer título, seja gratuito ou oneroso, sem prejuízo das medidas extra ou judiciais que entender cabíveis.
                    4.1.1. Na hipótese de constatação de uso indevido dos materiais e conteúdos das aulas do Curso pelo ALUNO, a CURSOLOGY, após apuradas as responsabilidades, poderá bloquear o acesso do ALUNO ao curso, sem prejuízo da apuração das perdas e danos causados.
                    4.2. O ALUNO poderá desistir do curso contratado, no prazo de 07 (sete) dias a contar de sua celebração, sem a incidência de multa e com a restituição de todos os valores pagos.
                    4.3. Caso o curso tenha iniciado, mas apenas parte das aulas tenham sido disponibilizadas pela CURSOLOGY, o ALUNO poderá resilir unilateralmente os presentes termos de uso, sendo certo que será descontado o valor proporcional às aulas disponibilizadas, assistidas ou não, bem como incidirá multa compensatória correspondente a 20% (vinte por cento) do valor relativo ao período que o ALUNO deixará de cursar ou o valor de uma mensalidade, prevalecendo o menor valor.
                    4.4. Em qualquer hipótese de rescisão, login e senha do ALUNO serão imediatamente bloqueados.
                    4.5. Em nenhuma hipótese será possível a troca de um curso contratado pelo ALUNO por outro curso da CURSOLOGY, na modalidade online ou não, tendo em vista a diversidade de aulas, disciplinas, investimentos realizados, administração e despesas da CURSOLOGY.
                    4.6. O instrutor de cada curso é responsável pelo envio das aulas, dos materiais complementares, e por sanar questionamentos dos ALUNOS relacionadas ao conteúdo do curso.
                    4.7. A Cursology não se responsabiliza pelo teor de publicações e demais atos de gerenciamento das redes sociais exclusivas dos instrutores.
                    5. PROCEDIMENTO EM CASO DE CONSTATAÇÃO DE ABUSOS OU IRREGULARIDADES
                    5.1. Caso o ALUNO identifique qualquer material ofensivo, ilegal, ou atentatório à moral e aos bons costumes, disponibilizado por outro usuário da plataforma, poderá, imediatamente comunicar à CURSOLOGY, através do site www.cursology.com.br, na seção “contato”, para que possa apurar a denúncia, ficando a CURSOLOGY isenta de qualquer responsabilidade por tal conteúdo, por ter sido realizado por terceiros, sem qualquer intervenção ou controle da CURSOLOGY.
                    5.2. Fica a critério da administração da CURSOLOGY a apuração das denúncias que lhe forem dirigidas.
                    5.3. O comportamento ilícito poderá ser sancionado com a suspensão ou cancelamento do cadastro do usuário na plataforma, sem prejuízo da adoção das medidas judiciais cabíveis
                    6. DISPOSIÇÕES GERAIS
                    6.1. A CURSOLOGY emitirá certificado de participação no curso. O certificado somente será expedido caso o ALUNO tenha cumprido com as disposições pedagógicas descritas na ficha técnica do curso. Demais regras para solicitação/emissão do certificado encontram-se no ambiente virtual (área do aluno no site).
                    6.2. A CURSOLOGY detém todos os direitos sobre o conteúdo intelectual das aulas, previamente elaborado pelos seus professores, e por eles ministrado, quando da apresentação de suas aulas. Fica expressamente proibida a reprodução das aulas, bem como a utilização de seu conteúdo, para fins comerciais ou diversos do contratado nesse instrumento, sem a expressa autorização da CURSOLOGY. A violação ao disposto supra, sujeitará o aluno às sanções previstas no art. 84 do Código Penal e arts. 102 e 104 da Lei n° 9.610/98 (Lei dos Direitos Autorais).
                    6.3. A CURSOLOGY poderá disponibilizar aulas ou outras atividades em caráter aberto, não exclusivo aos alunos matriculados, sem que tal se constitua em alteração contratual ou concessão de direito. A CURSOLOGY poderá, ainda, prestar serviços não abrangidos por estes termos de uso, tais como materiais de apoio, apostilas, disciplinas especiais ou avulsas, dentre outros, devendo o ALUNO arcar com os custos de acordo com a política de preços praticada pela CURSOLOGY.
                    6.4. O ALUNO declara-se ciente e concorda que o acesso ao conteúdo do curso não possui relação com o número de parcelas do curso, podendo este ser integralmente cursado em prazo inferior ou superior ao prazo de financiamento. A conclusão do curso não será considerada motivo para interromper o pagamento de quaisquer parcelas do financiamento escolhido pelo ALUNO.
                    6.5. A CURSOLOGY poderá, a qualquer momento, alterar/modificar os presentes termos de uso mediante aditivo contratual.
                    6.6. O ALUNO é responsável civil e criminalmente pela veracidade dos dados, declarações, informações e documentos que fornece e pelas consequências que deles advierem.
                    6.7. A tolerância das partes não implica em renúncia, perdão, novação ou alteração do pactuado neste instrumento, podendo a parte que se sentir lesada exigir o seu direito a qualquer tempo.
                    6.8. Se qualquer das cláusulas destes termos de uso for considerada nula ou anulável, tal cláusula será desconsiderada dos termos de uso, permanecendo as demais válidas, em pleno vigor e efeito.
                    6.9. O ALUNO declara ter lido previamente estes termos de uso e a ficha técnica do curso, concordado com todos os seus termos e condições, podendo a ficha técnica do curso ser unilateralmente modificada pela CURSOLOGY.
                    7. DO FORO
                    7.1. Fica eleito o foro do domicílio do ALUNO como competente para dirimir quaisquer questões oriundas destes termos de uso, com renúncia de qualquer outro, por mais privilegiado que seja.
                    Ao comprar um curso, você declara que leu e está de acordo com os termos e condições de uso.

                </Typography>
            </Paper>

        </Container>
    );
}

export default TermsOfUse;