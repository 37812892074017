import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

function QuestionDialog(props) {
    const { t } = useTranslation();

    return (<Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
            <DialogContentText color="primary">
                {props.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.noHandler} color="primary">
                {t('dialog.no')}
            </Button>
            <Button onClick={props.yesHandler} color="primary" autoFocus>
                {t('dialog.yes')}
            </Button>
        </DialogActions>
    </Dialog>);
}

export default QuestionDialog;