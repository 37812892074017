import { Button, Container, Grid, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import Analytics from '../base/Analytics';
import Client from '../base/Client';
import ErrorDialog from '../components/error-dialog';
import QuestionDialog from '../components/question-dialog';
import TextTitle from '../components/text-title';
import CopyrightFrame from './frames/CopyrightFrame';

function MaketProductRegistration() {
    const ajax = new Client();
    const [exitPage, setExitPage] = React.useState(false);
    const [reloadPage, setReloadPage] = React.useState(false);
    const { productId } = useParams();
    const { register, errors, handleSubmit, setValue } = useForm();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [marketProduct, setMarketProduct] = React.useState(null);
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [firstCall, setFirstCall] = React.useState(true);
    const [deleteDialog, setDeleteDialog] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        paper: {
            flexGrow: 1,
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
    }));

    const classes = useStyles();

    const errorCloseHandler = () => {
        setErrorMsg("");

        if (exitPage) {
            backHandler();
        } else if (reloadPage) {
            let reloadLocation = `/registration/market/${marketProduct.id}`;
            document.location.href = reloadLocation;
        }
    }

    const deleteNoHandler = () => {
        setDeleteDialog(false);
    }

    const deleteYesHandler = () => {
        setLoading(true);
        ajax.send("delete", `/adm/v1/market/${marketProduct.id}`)
            .then(response => {
                if (response.status === 200) {
                    setMarketProduct(null);
                    setErrorMsg(t('text.register.deleted'));
                    setExitPage(true);
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
                setDeleteDialog(false);
            });
    }

    const backHandler = () => {
        document.location.href = `/registration/market/search`;
    }

    const deleteHandler = () => {
        setDeleteDialog(true);
    }

    const onSubmit = (data) => {
        //Colocando load
        setLoading(true);

        //executa a chamada
        let url = `/adm/v1/market/`;
        let action = "post";

        if (marketProduct !== null && marketProduct !== undefined) {
            url = `/adm/v1/market/${productId}`;
            action = "put";
        }

        ajax.send(action, url, data)
            .then(response => {
                setMarketProduct(response.data);

                if (response.status === 200) {
                    setErrorMsg(t('text.register.save'));
                } else if (response.status === 201) {
                    setReloadPage(true);
                    setErrorMsg(t('text.register.insert'));
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
            });
    }

    const loadContent = useCallback(
        () => {
            const client = new Client();
            if (marketProduct === null && productId !== undefined) {
                setLoading(true);
                client.send("get", `/adm/v1/market/${productId}`, null)
                    .then(data => {
                        if (data.data !== undefined && data.data !== null && data.status === 200) {
                            setValue('title', data.data.title);
                            setValue('description', data.data.description);
                            setValue('logo', data.data.logo);
                            setValue('price', data.data.price);
                            setValue('url', data.data.url);
                            setMarketProduct(data.data);
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        },
        [marketProduct, productId, setValue],
    );

    useEffect(() => {
        if (firstCall === true) {
            loadContent();

            //Google
            const analytics = new Analytics();
            analytics.pageView();

            //foi a primeira vez
            setFirstCall(false);
        }

    }, [marketProduct, loadContent, firstCall]);

    return (<Container maxWidth="md" >
        <Helmet>
            <title data-react-helmet="true">Cadastrar Item</title>
            <meta name="description" content="Cadastrar produtos para o mercado interno" data-react-helmet="true" />
            <link rel="canonical" href={`/registration/market/${productId}`} data-react-helmet="true" />
        </Helmet>

        <QuestionDialog open={deleteDialog} yesHandler={deleteYesHandler} noHandler={deleteNoHandler} message={t('dialog.delete.content')} />

        <ErrorDialog onClose={errorCloseHandler} errorMsg={errorMsg} />

        <TextTitle>{t('text.market.register')}</TextTitle>

        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            variant="outlined"
                            label={t('input.title')}
                            name="title"
                            required
                            fullWidth
                            inputRef={register({
                                required: t('input.required')
                                , minLength: {
                                    value: 2,
                                    message: t('input.invalid.size', { from: '2', to: '200' })
                                }, maxLength: {
                                    value: 200,
                                    message: t('input.invalid.size', { from: '2', to: '200' })
                                }
                            })}
                            placeholder={t('input.title')}
                            size="small"
                            error={!!errors.title}
                            helperText={!!errors.title && errors.title.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            variant="outlined"
                            label={t('input.description')}
                            name="description"
                            required
                            fullWidth
                            inputRef={register({
                                required: t('input.required')
                                , minLength: {
                                    value: 2,
                                    message: t('input.invalid.size', { from: '2', to: '250' })
                                }, maxLength: {
                                    value: 250,
                                    message: t('input.invalid.size', { from: '2', to: '250' })
                                }
                            })}
                            placeholder={t('input.description')}
                            size="small"
                            error={!!errors.description}
                            helperText={!!errors.description && errors.description.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={t('input.logo')}
                            name="logo"
                            required
                            inputRef={register({
                                required: t('input.required')
                                , minLength: {
                                    value: 1,
                                    message: t('input.invalid.size', { from: '1', to: '100' })
                                }, maxLength: {
                                    value: 100,
                                    message: t('input.invalid.size', { from: '1', to: '100' })
                                }
                            })}
                            placeholder={t('input.logo')}
                            size="small"
                            fullWidth
                            error={!!errors.logo}
                            helperText={!!errors.logo && errors.logo.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={t('input.price')}
                            name="price"
                            inputRef={register({
                                minLength: {
                                    value: 1,
                                    message: t('input.invalid.size', { from: '1', to: '10000' })
                                }, maxLength: {
                                    value: 10000,
                                    message: t('input.invalid.size', { from: '1', to: '10000' })
                                }
                            })}
                            placeholder={t('input.price')}
                            size="small"
                            fullWidth
                            error={!!errors.price}
                            helperText={!!errors.price && errors.price.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={t('input.url')}
                            name="url"
                            inputRef={register({
                                minLength: {
                                    value: 1,
                                    message: t('input.invalid.size', { from: '1', to: '250' })
                                }, maxLength: {
                                    value: 200,
                                    message: t('input.invalid.size', { from: '1', to: '250' })
                                }
                            })}
                            placeholder={t('input.url')}
                            size="small"
                            fullWidth
                            error={!!errors.url}
                            helperText={!!errors.url && errors.url.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.buttons}>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={backHandler} >{t('button.back')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.save')}</Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button fullWidth disabled={loading} variant="contained" onClick={deleteHandler} color="secondary">{t('button.delete')}</Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>

        <CopyrightFrame />
    </Container>);
}

export default MaketProductRegistration;
