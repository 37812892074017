import axios from 'axios';
import Storages from './Storages';
import { Env } from './Env';

class Client {
    constructor() {
        //Criando uma variavel com a configuracao
        this.service = axios.create({
            validateStatus: function (status) {
                return status >= 200 && status < 300; // default
            },
        });

        //Base da URL
        if (Env.ambiente === "producao") {
            this.apiBaseUrl = Env.producao.servidor;
        } else {
            this.apiBaseUrl = Env.local.servidor;
        }

        //Interceptador
        this.service.interceptors.request.use(
            config => {
                const { origin } = new URL(config.url);
                const allowedOrigins = [this.apiBaseUrl];
                const storages = new Storages();
                const token = storages.getToken();

                if (token !== null && allowedOrigins.includes(origin)) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }

                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

    }

    send(verbo, urlTarget, bodyJson) {
        const serviceInt = this.service;
        const apiBaseUrlInt = this.apiBaseUrl;

        return new Promise(function (resolve, reject) {
            //Adiciona parametro na chamada
            serviceInt.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
            serviceInt.method = verbo.toLowerCase();
            serviceInt.url = apiBaseUrlInt + urlTarget;
            serviceInt.data = bodyJson;
            serviceInt.withCredentials=true;

            //executa a chamada
            serviceInt.request(serviceInt).then(async response => {

                // debugger
                // check for error response
                if (response.status >= 200 && response.status < 300) {
                    // if (response.data !== null && response.data !== "") {
                    //Salvando no storage
                    //if (response.data.data != null && response.data.data.token) {
                    //const storages = new Storages();
                    // storages.setToken(response.data.data.token);
                    //localStorage.setItem("token", response.data.data.token);
                    // }
                    //  }

                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch(response => {
                response.customMsg = null;
                const storages = new Storages();

                if (response && response.response === undefined) {
                    response.customMsg = "Falha na comunição com a internet.";

                } else if (response.response.status === 401) {
                    //debugger
                    response.customMsg = "[401] Conteúdo não autorizado, necessário fazer um login.";
                    storages.setIntentUrl(window.location.pathname);

                } else if (response.response.status === 403) {
                    response.customMsg = "[403] Conteúdo não autorizado nesse perfil.";
                    storages.setIntentUrl(null);

                } else if (response.response.status === 404) {
                    response.customMsg = "[404] Conteúdo inexistente";
                    storages.setIntentUrl(null);

                } else if (response
                    && response.response.data !== null
                    && response.response.data !== ""
                    && response.response.data.errors) {

                    let msg = `[${response.response.status}] `;
                    response.response.data.errors.forEach(element => {
                        msg += element.msg + "\r\n";
                    });

                    if (msg !== "") {
                        response.customMsg = msg;
                    }
                }

                reject(response);
            });
        });
    }

}

export default Client;