import { Button, Grid, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Client from '../base/Client';
import ErrorDialog from '../components/error-dialog';
import CopyrightFrame from './frames/CopyrightFrame';
import UserPerfil from './frames/UserPerfil';
import Typography from '@material-ui/core/Typography';

function RequestContent() {

    const useStyles = makeStyles((theme) => ({
        main: {
            padding: theme.spacing(2) + 'px ' + theme.spacing(0) + 'px',
            maxWidth: "1280px",
            margin: "auto",
        },
        title: {
            display: 'inline-block',
            padding: theme.spacing(2),
            paddingTop: theme.spacing(0),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
            '@media (max-width:780px)': {
                '& h4': {
                    fontSize: "18pt",
                },
            },
        },
        paper: {
            padding: theme.spacing(4),
            width: '100%',
        },
        buttons: {
            width: '100%',
        },
        root: {
            padding: theme.spacing(0),
            width: '100%',
            paddingTop: theme.spacing(0),
        },
        logos: {
            width: '128px',
            height: '128px',
        },
    }));

    const ajax = new Client();
    const { register, errors, handleSubmit } = useForm();
    const [errorMsg, setErrorMsg] = React.useState(null);
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);

    const errorCloseHandler = () => {
        setErrorMsg("");
    }

    const onSubmit = (data) => {
        //Colocando load
        setLoading(true);

        //executa a chamada
        let url = `/priv/v1/market/request-content`;
        let action = "post";

        ajax.send(action, url, data)
            .then(response => {
                if (response.status === 200) {
                //    setErrorMsg(t('text.register.save'));
                   document.location.href="/market/content/sucess";
                } else if (response.status === 201) {
                    // setErrorMsg(t('text.register.insert'));
                    document.location.href="/market/content/sucess";
                }
            })
            .catch(response => {
                setErrorMsg(response.customMsg);
            }).finally(() => {
                setLoading(false);
            });
    }

    return (
        <div className={classes.main}>

            <UserPerfil />

            <ErrorDialog onClose={errorCloseHandler} errorMsg={errorMsg} />

            <div className={classes.title}>
                <Typography variant="h4"> :: Consultoria :: </Typography>
            </div>

            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Typography variant="body2" component="p" >{t("text.request.content")}</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    variant="outlined"
                                    label={t('input.userObservation')}
                                    name="userObservation"
                                    required
                                    fullWidth
                                    multiline
                                    rows={5}
                                    height="100px"
                                    inputRef={register({
                                        required: t('input.required')
                                        , minLength: {
                                            value: 2,
                                            message: t('input.invalid.size', { from: '2', to: '500' })
                                        }, maxLength: {
                                            value: 500,
                                            message: t('input.invalid.size', { from: '2', to: '500' })
                                        }
                                    })}
                                    placeholder={t('input.userObservation')}
                                    size="small"
                                    error={!!errors.userObservation}
                                    helperText={!!errors.userObservation && errors.userObservation.message}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary">{t('button.send')}</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </div>
            <CopyrightFrame />

        </div>
    );
}

export default RequestContent;