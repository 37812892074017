import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import React from 'react';
import PropTypes from 'prop-types';

function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: props.threshold
    });

    const sizeControl = document.body.clientHeight - 100;

    let allowAnimation = true;
    if (sizeControl <= window.innerHeight) {
        allowAnimation = false;
    } else {
        allowAnimation = trigger;
    }

    return React.cloneElement(children, {
        className: allowAnimation ? props.classFloat : props.classStatic
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired
};

export default ElevationScroll;