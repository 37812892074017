import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
// import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircleTwoToneIcon from '@material-ui/icons/ViewStreamOutlined';
// import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import YouTube from 'react-youtube';
import Typed from 'typed.js';
import Analytics from '../base/Analytics';
import Client from '../base/Client';
import Storages from '../base/Storages';
import Utils from '../base/Utils';
import Code from '../components/code';
import ErrorDialog from '../components/error-dialog';
import TextTitle from '../components/text-title';
import CopyrightFrame from './frames/CopyrightFrame';
import Notification from '../components/firebase/notification';
import Lottie from "lottie-react";
import partyPopper from "../json/partypopper.json";

function Product(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            margin: theme.spacing(2),
            marginTop: '0px',
        },
        title: {
            padding: theme.spacing(1),
        },
        chaptersTitle: {
            padding: theme.spacing(1),
            textAlign: 'center',
        },
        brackets: {
            color: theme.palette.text.secondary,
        },
        videoMain:{
            background: 'radial-gradient(circle, rgba(48,48,48,1) 0%, rgba(33,33,33,1) 100%, rgba(48,48,48,1) 100%)',
        },
        video: {
            margin: 'auto',
            display: 'block',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            }
        },
        videoRoot: {
            marginTop: theme.spacing(2),
            width: '100%',
        },
        leftMenu: {
            width: '100%',
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        heading: {
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
        itemsMenu: {
            padding: '0px',
        },
        activeItem: {
            backgroundColor: "#b9b67775", //#b9b67740",
        },
        itemChecked: {
            color: theme.palette.primary.checked,
        },
        itemUnchecked: {
            color: theme.palette.primary.main,
        },
        partyPopper: {
            width: '370px',
            height: '450px',
            position: 'fixed',
            bottom: '0px',
            left: '10px',
            zIndex: 10000,
            cursor: 'pointer',
            display: 'none',
        }
    }));

    const lottieRef = React.useRef();
    const [stylePartyPopper, setStylePartyPopper] = React.useState({});
    const [visualizationDTO, setVisualizationDTO] = React.useState({ status: null, id: null });
    // const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const classes = useStyles();
    const { itemId, productId } = useParams();
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [productItem, setProductItem] = React.useState(null);
    const [product, setProduct] = React.useState(null);
    const [subscription, setSubscription] = React.useState(null);
    const [expanded, setExpanded] = React.useState("panel2");
    const [variableItemId, setVariableItemId] = React.useState(null);
    const [loadingProduct, setLoadingProduct] = React.useState(false);
    const [loadingItem, setLoadingItem] = React.useState(false);
    const [loadingSubscription, setLoadingSubscription] = React.useState(false);
    const [loadingTerminal, setLoadingTerminal] = React.useState(false);
    const [loadingVisualization, setLoadingVisualization] = React.useState(false);
    const [connError, setConnError] = React.useState(false);

    const closePartyPopper = () => {
        lottieRef.current.goToAndStop(0, false);
        setStylePartyPopper({ "display": "none" });
    }

    const openPartyPopper = () => {
        setStylePartyPopper({ "display": "block" });
        lottieRef.current.play();
    }

    const loadTerminalContent = useCallback(
        () => {

            if (loadingTerminal === false && productItem !== null) {
                const description = document.querySelector("#description");
                setLoadingTerminal(true);

                if (description !== null) {
                    if (productItem.description !== undefined) {
                        new Typed(description, {
                            strings: [productItem.description],
                            typeSpeed: 30,
                            loop: false,
                            showCursor: false,
                            onBegin: () => {
                                let cursor = document.querySelectorAll(".typed-cursor--blink");

                                if (cursor !== null) {
                                    cursor.forEach((n) => {
                                        n.remove();
                                    });
                                }
                            },
                            onComplete: () => {
                            }
                        });
                    }
                }
            }
        },
        [productItem, loadingTerminal],
    );

    const loadItemContent = useCallback(
        () => {
            const client = new Client();

            if (product !== null && productItem === null && variableItemId !== null && loadingItem === false) {

                setLoadingItem(true);

                if (variableItemId === 0) {
                    //Define qual url será usada
                    let url = null;

                    //Define se é um produto fechado
                    if (product.privy === 0) {
                        url = `/pub/v1/product/${productId}`;
                    } else {
                        url = `/priv/v1/product/${productId}`;
                    }

                    client.send("get", url, null)
                        .then(data => {
                            if (data.status === 200) {
                                if (data.data !== undefined) {
                                    setProductItem(data.data);
                                }
                            } else if (data.status === 204) {
                                setProductItem({});
                            } else {
                                setProductItem(null);
                            }

                        }).catch(error => {
                            setErrorMsg(error.customMsg);
                            //se der erro, nao tenta mais consultar e fixa na introducao
                            setConnError(true);
                        }).finally(data => {
                            setLoadingItem(false);
                        });


                } else {
                    //Abre o painel se tiver um item selecionado
                    let existeItem = false;

                    for (let i = 0; i < product.chapters.length; i++) {
                        for (let j = 0; j < product.chapters[i].items.length; j++) {
                            if (product.chapters[i].items[j].id === parseInt(variableItemId)) {
                                setExpanded(product.chapters[i].id);
                                existeItem = true;
                                break;
                            }
                        }
                    }

                    if (existeItem === false) {
                        setErrorMsg("Esse item não pertence à esse produto.");
                    }

                    //Define qual url será usada
                    let url = null;

                    //Define se é um produto fechado
                    if (product.privy === 0) {
                        url = `/pub/v1/product/item/${variableItemId}`;
                    } else {
                        url = `/priv/v1/product/item/${variableItemId}`;
                    }

                    client.send("get", url, null)
                        .then(data => {
                            if (data.status === 200) {
                                if (data.data !== undefined) {
                                    setProductItem(data.data);
                                }
                            } else if (data.status === 204) {
                                setProductItem({});
                            } else {
                                setProductItem(null);
                            }

                        }).catch(error => {
                            setErrorMsg(error.customMsg);
                            //se der erro, nao tenta mais consultar e fixa na introducao
                            setConnError(true);
                        }).finally(data => {
                            setLoadingItem(false);
                        });
                }
            }
        },
        [variableItemId, productItem, product, loadingItem, productId],
    );

    const loadSubscription = useCallback(
        () => {
            const client = new Client();
            const storage = new Storages();

            setLoadingSubscription(true);

            if (loadingItem === false && storage.getUser() !== null && product === null && product !== undefined) {
                client.send("get", `/priv/v1/product/${productId}/registration`, null)
                    .then(data => {
                        if (data.status === 200) {
                            setSubscription(data.data);
                        } else {
                            setSubscription(null);
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                        setConnError(true);
                    }).finally(data => {
                        setLoadingSubscription(false);
                    });
            }
        },
        [product, productId, loadingItem],
    );

    const changePageTo = (e, itemId) => {
        if (e != null) {
            e.stopPropagation();
        }
        setProductItem(null);
        setVariableItemId(itemId);
        setVisualizationDTO({ ...visualizationDTO, status: null });
    }

    const changePageToCall = useCallback(
        (itemId) => {
            setProductItem(null);
            setVariableItemId(itemId);
            setVisualizationDTO({ ...visualizationDTO, status: null });
        },
        [visualizationDTO]);

    const loadProduct = useCallback(
        () => {
            const client = new Client();
            const storage = new Storages();

            if (product === null && loadingProduct === false) {
                setLoadingProduct(true);

                let url = `/pub/v1/product/${productId}/menu`;
                if (storage.getUser() !== null) {
                    url = `/priv/v1/product/${productId}/menu`;
                }

                client.send("get", url, null)
                    .then(data => {
                        if (data.status === 204) {
                            setConnError(true);
                            setErrorMsg("Conteúdo inexistente ou não publicado.");
                        } else if (data.data !== undefined && data.data !== null && data.status === 200) {
                            //Salva produto
                            setProduct(data.data);

                            //Verifica se é introducao ou nao
                            if (itemId !== undefined) {
                                setVariableItemId(itemId);
                            } else {

                                let lastSeenItemId = null;
                                let lastSeenDate = null;
                                data.data.chapters.forEach(chapter => {
                                    chapter.items.forEach(item => {
                                        if (item.userItem !== null && item.userItem.length > 0) {
                                            let utils = new Utils();
                                            if (lastSeenItemId === null) {
                                                lastSeenItemId = item.id;
                                                lastSeenDate = utils.formatUTCStringToDate(item.userItem[0].registrationDate);
                                            } else {
                                                let itemDate = utils.formatUTCStringToDate(item.userItem[0].registrationDate);
                                                if (itemDate.getTime() > lastSeenDate.getTime()) {
                                                    lastSeenItemId = item.id;
                                                    lastSeenDate = itemDate;
                                                }
                                            }
                                        }
                                    });
                                });

                                //Carrega o ultimo item visto
                                if (lastSeenItemId !== null) {
                                    changePageToCall(lastSeenItemId);
                                } else {
                                    setVariableItemId(0);
                                    setProductItem(data.data);
                                    setExpanded('panel_introduction');
                                }
                            }

                            //Carrega subscricao
                            loadSubscription();
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                        setConnError(true);
                    }).finally(data => {
                        setLoadingProduct(false);
                    });
            }
        },
        [product, productId, itemId, loadingProduct, loadSubscription, changePageToCall],
    );

    const handleClose = () => {
        setErrorMsg("");
    }

    // const handleSnackbar = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }

    //     // setOpenSnackbar(false);
    // }

    const handleAccordion = (id) => {
        if (id === expanded) {
            setExpanded(null);
        } else {
            setExpanded(id);
        }
    }

    const checkVisualizationFunction = useCallback(() => {
        const storage = new Storages();
        if (document.timerToSaveVisualization) {
            clearTimeout(document.timerToSaveVisualization);
        }

        if (storage.getUser() && product && productItem
            && variableItemId
            && variableItemId !== 0
            && loadingVisualization === false
            && (visualizationDTO.status || (visualizationDTO.status === null && (productItem.youtube === null || productItem.youtube === "")))) {
            const client = new Client();

            if (visualizationDTO.status === "created") {
                document.timerToSaveVisualization = setTimeout(() => {
                    setVisualizationDTO({ ...visualizationDTO, status: "saving" });
                }, ((productItem.durationExpectation + 5) * 1000));

            } else if (visualizationDTO.status === "savingerror") {
                document.timerToSaveVisualization = setTimeout(() => {
                    setVisualizationDTO({ ...visualizationDTO, status: "saving" });
                }, 30000);

            } else if (visualizationDTO.status === "creatingerror") {
                document.timerToSaveVisualization = setTimeout(() => {
                    setVisualizationDTO({ ...visualizationDTO, status: "ready" });
                }, 30000);

            } else if (visualizationDTO.status === "saving") {
                client.send("put", `/priv/v1/product/item/${productItem.id}/followup`, { visualizationId: visualizationDTO.id })
                    .then(data => {

                        if (product !== null) {
                            product.chapters.forEach(chapter => {
                                chapter.items.forEach(item => {
                                    if (item.id === productItem.id) {
                                        item.userItem = [data.data];
                                    }
                                });
                            });
                        }

                        setVisualizationDTO({ ...visualizationDTO, status: "saved" })
                        // setOpenSnackbar(true);
                        openPartyPopper();

                    }).catch(error => {
                        setVisualizationDTO({ ...visualizationDTO, status: "savingerror" })
                    }).finally(data => {
                        setLoadingVisualization(false);
                    });

            } else if (visualizationDTO.status === "ready") {
                setVisualizationDTO({ status: "creating" });
                client.send("get", `/priv/v1/product/item/${productItem.id}/followupId`, null)
                    .then(data => {
                        setVisualizationDTO({ ...visualizationDTO, status: "created", id: data.data.visualizationId })

                    }).catch(error => {
                        setVisualizationDTO({ ...visualizationDTO, status: "creatingerror" });
                    }).finally(data => {
                        setLoadingVisualization(false);
                    });
            } else if (visualizationDTO.status === null && (productItem.youtube === null || productItem.youtube === "")) {

                setVisualizationDTO({ status: "creating" });
                client.send("get", `/priv/v1/product/item/${productItem.id}/followupId`, null)
                    .then(data => {
                        setVisualizationDTO({ ...visualizationDTO, status: "created", id: data.data.visualizationId })

                    }).catch(error => {
                        setVisualizationDTO({ ...visualizationDTO, status: "creatingerror" });
                    }).finally(data => {
                        setLoadingVisualization(false);
                    });
            }

        }

        return () => {
            clearTimeout(document.timerToSaveVisualization);
        };
    },
        [product, productItem, visualizationDTO, variableItemId, loadingVisualization],
    );

    const handleVisualization = (event) => {
        setVisualizationDTO({ ...visualizationDTO, status: "ready" });
    }

    const handlerSubscription = (idProduct) => {
        const client = new Client();

        setLoadingSubscription(true);

        if (subscription === null) {
            client.send("post", `/priv/v1/product/${productId}/registration`, null)
                .then(data => {
                    setSubscription(data.data);
                }).catch(error => {
                    setErrorMsg(error.customMsg);
                    setSubscription(null);
                    setConnError(true);
                }).finally(data => {
                    setLoadingSubscription(false);
                });

        } else {
            client.send("delete", `/priv/v1/product/${productId}/registration`, null)
                .then(data => {
                    setSubscription(null);
                }).catch(error => {
                    setErrorMsg(error.customMsg);
                    setConnError(true);
                }).finally(data => {
                    setLoadingSubscription(false);
                });
        }
    }

    const buildAccordionItems = (item, index) => {
        return (<ListItem className={(item.id === parseInt(variableItemId)) ? classes.activeItem : null} button onClick={(e) => changePageTo(e, item.id)} key={`item_${item.id}`} >
            <ListItemIcon style={{ minWidth: '35px' }}><AccountCircleTwoToneIcon className={item !== null && item.userItem != null && item.userItem.length > 0 ? classes.itemChecked : classes.itemUnchecked} /></ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2', noWrap: false }} primary={item.title} />
        </ListItem>)
    }

    const buildAccordionChapters = (chapter, index) => {
        return (<Accordion expanded={expanded === chapter.id} onClick={() => { handleAccordion(chapter.id) }} key={`accordion_${index}`}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body2"><span className={classes.brackets}>{chapter.order}. </span>{chapter.title}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0px' }}>
                <div style={{ width: '100%' }}>
                    {chapter.items.length > 0 && chapter.items.map(buildAccordionItems)}
                </div>
            </AccordionDetails>
        </Accordion>);
    }

    const buildItemIntro = () => {
        return (
          <React.Fragment>
            <TextTitle>Introdução</TextTitle>

            <Typography variant="body1" gutterBottom>
              <span id="description"></span>
            </Typography>

            <Typography variant="body1" gutterBottom>
              Status do assunto:{" "}
              <span className={classes.brackets}>{product.statusName}</span>
            </Typography>

            {productItem.youtube !== null && productItem.youtube !== "" && (
              <div className={classes.videoMain}>
                <YouTube
                  videoId={productItem.youtube}
                  id={"video"}
                  className={classes.video}
                  containerClassName={classes.videoRoot}
                ></YouTube>
              </div>
            )}

            {productItem.content != null && (
              <Code>
                {productItem.content === null ? "" : productItem.content}
              </Code>
            )}
          </React.Fragment>
        );
    }

    const buildItemContent = () => {
        return (
          <React.Fragment>
            <TextTitle>{productItem.title}</TextTitle>

            <Typography variant="body1" gutterBottom>
              <span id="description"></span>
            </Typography>

            {productItem.youtube !== null && productItem.youtube !== "" && (
              <div className={classes.videoMain}>
                <YouTube
                  videoId={productItem.youtube}
                  id={"video"}
                  className={classes.video}
                  containerClassName={classes.videoRoot}
                  onPlay={handleVisualization}
                ></YouTube>
              </div>
            )}

            {productItem.content != null && (
              <Code>
                {productItem.content === null ? "" : productItem.content}
              </Code>
            )}
          </React.Fragment>
        );
    }

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        if (connError === false) {
            loadProduct();
            loadItemContent();
            loadTerminalContent();
            checkVisualizationFunction();
        }

    }, [loadProduct, loadItemContent, loadTerminalContent, loadingItem,
        variableItemId, loadAnalytics, connError, checkVisualizationFunction]);

    return (<div className={classes.root} >

        <Helmet>
            <title data-react-helmet="true">Assunto</title>
            {product !== null && (<meta name="description" content={product.description} data-react-helmet="true" />)}
            {itemId && (<link rel="canonical" href={`https://www.byiorio.com.br/seo/item.php?itemId=${itemId}&productId=${productId}`} data-react-helmet="true" />)}
            {!itemId && (<link rel="canonical" href={`https://www.byiorio.com.br/seo/product.php?productId=${productId}`} data-react-helmet="true" />)}
        </Helmet>

        <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />
{/* 
        <Snackbar
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={openSnackbar}
            onClose={handleSnackbar}
        >
            <Alert onClose={handleSnackbar} severity="success" sx={{ width: '100%' }} variant="filled">
                Registramos que esse item foi visualizado em sua conta.
            </Alert>
        </Snackbar> */}

        <Lottie lottieRef={lottieRef} style={stylePartyPopper} animationData={partyPopper} className={classes.partyPopper} loop={false} autoplay={false} onClick={closePartyPopper} />

        <div className={classes.heading}>
            {product === null ? (
                <Skeleton variant="rect" />
            ) : (
                <Paper className={classes.title}>
                    <Grid container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={12} sm={2} >
                            <Typography variant="caption">Assunto <span className={classes.brackets}>{product.statusName.toLowerCase()}</span></Typography>
                        </Grid>

                        <Grid item xs={12} sm={8} >
                            <Typography >
                                <span className={classes.brackets}>::: </span>
                                <span> {product.title} </span>
                                <span className={classes.brackets}>::: </span>
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2} >
                            <Notification />
                            <Button fullWidth disabled={loadingSubscription} onClick={() => handlerSubscription(product.id)} type="submit" color="primary" className={classes.submit} variant="outlined">
                                {subscription === null ? "Inscrever-se" : "Descadastrar-se"}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </div>
        <Grid container spacing={2} >
            <Grid item sm={3} xs={12}>
                <Paper>
                    <Typography variant="subtitle2" className={classes.chaptersTitle}>
                        <span className={classes.brackets}>::: </span>
                        <span>  Capítulos  </span>
                        <span className={classes.brackets}>::: </span>
                    </Typography>
                    {product === null || product === undefined ? (
                        <React.Fragment >
                            <Skeleton variant="rect" height={400} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment >
                            <Accordion square expanded={expanded === "panel_introduction"} onClick={() => { handleAccordion('panel_introduction') }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="body2"><span className={classes.brackets}>0. </span>Introdução</Typography>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <ListItem button onClick={(e) => changePageTo(e, 0)} key={`item_main`} className={(variableItemId === 0) ? classes.activeItem : null} >
                                        <ListItemIcon style={{ minWidth: '35px' }}><AccountCircleTwoToneIcon color='primary' /></ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ variant: 'body2', noWrap: false }} primary={'Introdução'} />
                                    </ListItem>
                                </AccordionDetails>

                            </Accordion>
                            {product.chapters.length > 0 && product.chapters.map(buildAccordionChapters)}
                        </React.Fragment >
                    )}
                </Paper>
            </Grid>
            <Grid item sm={9} xs={12}>
                <div id="itemContent" className={classes.internalDiv}>
                    <Paper className={classes.leftMenu}>
                        {productItem === null ? (
                            <React.Fragment >
                                <TextTitle><Skeleton style={{ display: 'inline-block', width: '100px' }} /></TextTitle>

                                <Typography variant="body1" gutterBottom noWrap>
                                    <Skeleton variant="rect" />
                                </Typography>

                                <Skeleton variant="rect" className={classes.video} height={300} />
                            </React.Fragment>
                        ) : (
                            variableItemId === 0 ? buildItemIntro() : buildItemContent()
                        )}
                    </Paper>
                    <CopyrightFrame />
                </div>
            </Grid>
        </Grid>

    </div >);
}

export default Product;