
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'; //home
import PrivateRoute from './components/private-route';
import './i18n';
import About from './pages/About';
import Blog from './pages/Blog';
import BlogRegistration from './pages/BlogRegistration';
import BlogRegistrationSearch from './pages/BlogRegistrationSearch';
import ChapterRegistration from './pages/ChapterRegistration';
import ForgotPassword from './pages/ForgotPassword';
import NavFrame from './pages/frames/NavFrame';
import Home from './pages/Home';
import HomePerfil from './pages/HomePerfil';
import HomeUser from './pages/HomeUser';
import Intro from './pages/Intro';
import ItemRegistration from './pages/ItemRegistration';
import Login from './pages/Login';
import MaketProductRegistration from './pages/MaketProductRegistration';
import MarketProductSearch from './pages/MarketProductSearch';
import NotFound from './pages/NotFound';
import Product from './pages/Product';
import ProductCategory from './pages/ProductCategory';
import ProductRegistration from './pages/ProductRegistration';
import ProductRegistrationSearch from './pages/ProductRegistrationSearch';
import ProductSearch from './pages/ProductSearch';
import Register from './pages/Register';
import MarketProducts from './pages/MarketProducts';
import RequestContent from './pages/RequestContent';
import MarketTransactionSearch from './pages/MarketTransactionSearch';
import MarketTransactionRegistration from './pages/MarketTransactionRegistration';
import MarketTransactions from './pages/MarketTransactions';
import RequestContentSuccess from './pages/RequestContentSuccess';
import TermsOfUse from './pages/TermsOfUse';

function App() {
    return (
        <React.Fragment>
            <Suspense fallback={null}>
                <Router>
                    <NavFrame />
                    <Switch>
                        <Route path="/" exact component={Intro}></Route>
                        <Route path="/home" exact component={Home}></Route>
                        <Route path="/mario" render={() => {
                            return <Redirect to="https://www.byiorio.com.br/mario/index.html" />
                        }} />
                        <Route path="/register" component={Register}></Route>
                        <Route path="/blog/:blogId" component={Blog} exact />
                        <Route path="/about" component={About}></Route>
                        <Route path="/terms" component={TermsOfUse}></Route>
                        <Route path="/login" component={Login}></Route>
                        <Route path="/forgotPassword" component={ForgotPassword}></Route>
                        <Route path="/product/search" component={ProductSearch} exact />
                        <Route path="/product/category/" component={ProductCategory} exact />
                        <Route path="/product/category/:categoryId" component={ProductCategory} exact />
                        <Route path="/product/:productId" component={Product} exact />
                        <Route path="/product/:productId/item/:itemId" component={Product} exact />
                        <PrivateRoute path="/perfil" component={HomePerfil} exact />
                        <PrivateRoute path="/registration/product/search" component={ProductRegistrationSearch} exact />
                        <PrivateRoute path="/registration/product" component={ProductRegistration} exact />
                        <PrivateRoute path="/registration/product/:productId" component={ProductRegistration} exact />
                        <PrivateRoute path="/registration/product/:productId/chapter" component={ChapterRegistration} exact />
                        <PrivateRoute path="/registration/product/:productId/chapter/:chapterId" component={ChapterRegistration} exact />
                        <PrivateRoute path="/registration/product/:productId/chapter/:chapterId/item" component={ItemRegistration} exact />
                        <PrivateRoute path="/registration/product/:productId/chapter/:chapterId/item/:itemId" component={ItemRegistration} exact />
                        <PrivateRoute path="/welcome" component={HomeUser} exact />
                        <PrivateRoute path="/registration/blog/search" component={BlogRegistrationSearch} exact />
                        <PrivateRoute path="/registration/blog/" component={BlogRegistration} exact />
                        <PrivateRoute path="/registration/blog/:blogId" component={BlogRegistration} exact />
                        <PrivateRoute path="/registration/market/search" component={MarketProductSearch} exact />
                        <PrivateRoute path="/registration/market/" component={MaketProductRegistration} exact />
                        <PrivateRoute path="/registration/market/:productId" component={MaketProductRegistration} exact />
                        <PrivateRoute path="/market/" component={MarketProducts} exact />
                        <PrivateRoute path="/market/content" component={RequestContent} exact />
                        <PrivateRoute path="/registgration/market/transactions/search" component={MarketTransactionSearch} exact />
                        <PrivateRoute path="/registgration/market/transactions/:transactionId" component={MarketTransactionRegistration} exact />
                        <PrivateRoute path="/market/transactions" component={MarketTransactions} exact />
                        <PrivateRoute path="/market/content/sucess" component={RequestContentSuccess} exact />
                        <Route component={NotFound} />
                    </Switch>
                </Router>
            </Suspense>
        </React.Fragment>
    );
}

export default App;