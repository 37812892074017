import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import packageJson from '../../../package.json';

function CopyrightFrameFrame() {

  return (
    <div>
      <Typography variant="body2" color="textSecondary" align="center" style={{ margin: '16px 0px' }}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.byiorio.com.br/">
          {' www.byiorio.com.br '}
        </Link>
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="body2" align="center" style={{ margin: '16px 0px' }}>
        <img src="/assets/img/react.png" alt="Powered by React" height="32px" />
        <img src="/assets/img/springboot.png" alt="Powered by Spring boot" height="32px" />
        <img src="/assets/img/mysql.png"  alt="Powered by Mysql" height="32px" />
        <br />
        v{packageJson.version}
      </Typography>
    </div>
  );
}

export default CopyrightFrameFrame;