import Analytics from '../base/Analytics';
import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player'
import { Helmet } from "react-helmet";

function Intro() {
    const [loadAnalytics, setAnalytics] = React.useState(false);
    const [playing, setPlaying] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        intro: {
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundColor: "#212021",
            zIndex: "2000",
            margin: "auto",
            display: "grid",
            alignItems: "center",
            justifyItems: "center",
        },

    }));

    const classes = useStyles();

    useEffect(() => {
        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

    }, [loadAnalytics]);

    const handleReady = () => {
        // console.log("video pronto");
        // setMuted(false);
        setPlaying(true);
    }

    const handleEnded = (teste) => {
        console.log(teste);
        // console.log("video acabou");
        window.location.href = "/home";
    }

    return (
        <div className={classes.intro}>
            <Helmet>
                <title data-react-helmet="true">Início</title>
                <meta name="description" content="Video da logo www.byiorio.com.br" data-react-helmet="true" />
                <link rel="canonical" href={`/`} data-react-helmet="true" />
            </Helmet>

            <ReactPlayer key={'/assets/videos/intro.mp4'}
                playing={playing}
                url={'/assets/videos/intro.mp4'}
                onReady={handleReady}
                onEnded={handleEnded}
                onError={handleEnded}
                muted={true}
                controls={false}
                width="100%" />
        </div>
    );
}

export default Intro;