import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React, { useEffect, useState } from 'react';
import { onMessageListener, requestForToken } from './index';
import { makeStyles } from '@material-ui/core/styles';

const Notification = () => {
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [requestToken, setRequestToken] = React.useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });

    const useStyles = makeStyles((theme) => ({
        root: {
        },
        alert: {
            textAlign: 'left',
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        if (notification?.title) {
            setOpenSnackbar(true);
        }

        //Request
        if (requestToken === false) {
            requestForToken();
            setRequestToken(true);
        }

    }, [notification, requestToken])

    const handleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    }

    //Coloca um listnere para ler a mensagem
    onMessageListener()
        .then((payload) => {
            setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
        })
        .catch((err) => console.log('failed: ', err));

    return (
        <Snackbar
            // autoHideDuration={5000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={openSnackbar}
            onClose={handleSnackbar}
        >
            <Alert className={classes.alert} onClose={handleSnackbar} severity="info" sx={{ width: '100%' }} variant="filled">
            <AlertTitle>{notification.title}</AlertTitle>
            {notification.body}
            </Alert>
        </Snackbar>
    )
}

export default Notification;