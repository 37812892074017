
class Utils {

    formatDateYYYYDDMM(dateString) {

        if (dateString === null || dateString === "null"){
            return "";
        }

        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear()

        return year + "-" + month + "-" + day;
    }

    formatUTCStringToDate(dateSTring) {
        if (dateSTring !== null && dateSTring !== undefined){
            var dates = dateSTring.split(/-|T|:/);
            var newDate = new Date(dates[0], dates[1] - 1, dates[2], dates[3], dates[4]);
            return new Date(newDate);
        }else{
            return null;
        }
    }

}

export default Utils;