import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useForm } from "react-hook-form";
import Client from '../base/Client';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import ErrorDialog from '../components/error-dialog';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import CopyrightFrame from './frames/CopyrightFrame';
import Typed from 'typed.js';
import React, { useEffect, useCallback } from "react";
import Storages from '../base/Storages';
import Analytics from '../base/Analytics';
import { Helmet } from "react-helmet";
import FacebookLogin from 'react-facebook-login';

function Login(props) {
    const ajax = new Client();
    const [loading, setLoading] = React.useState(false);
    const { register, errors, handleSubmit } = useForm();
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loadMsg, setLoadMsg] = React.useState(false);
    const { t } = useTranslation();
    const [loadAnalytics, setAnalytics] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        root: {
            height: 'calc(100vh - 100px)',
            flexGrow: 1,
        },
        image: {
            backgroundImage: 'url(/assets/img/background/grandmother-1822560_1920.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
        paper: {
            margin: theme.spacing(8, 4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));

    const classes = useStyles();

    const responseFacebook = (response) => {
        console.log(response)
    }

    const clickedFacebook = (response) => {
        console.log(response)
    }

    const onSubmit = (data) => {

        //Colocando load
        setLoading(true);

        //Faz chamada para cadastrar usuario
        ajax.send("post", "/pub/v1/login/", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    const storages = new Storages();
                    // debugger
                    storages.setUser(response.data.data.user);

                    let url = storages.getIntentUrl();
                    if (url !== null && url !== undefined) {
                        storages.setIntentUrl(null);
                        document.location.href = url;

                    } else {
                        document.location.href = '/welcome';
                    }
                }
            })
            .catch(error => {
                if (error != null) {
                    setErrorMsg(error.customMsg);
                }

                setLoading(false);
            });
    };

    const handleClose = () => {
        setErrorMsg("");
    }
    const loadTerminalContent = useCallback(
        () => {
            new Typed('#welcome', {
                strings: ["Seja Bem vindo", "Digite o usuário e senha para entrar."],
                typeSpeed: 30,
                onBegin: function (pos, self) {
                    let cursor = document.querySelectorAll(".typed-cursor");
                    if (cursor !== null) {
                        cursor.forEach((n) => {
                            n.remove();
                        });
                    }
                },
            });
        },
        [],
    );

    const msgErro = useCallback(
        () => {
            const storages = new Storages();
            let url = storages.getIntentUrl();

            if (loadMsg === false
                && url !== null
                && url !== undefined) {
                setErrorMsg("Você será redirecionado para a página desejada após o login");
                setLoadMsg(true);
            }
        },
        [loadMsg],
    );

    useEffect(() => {

        if (!loadAnalytics) {
            const analytics = new Analytics();
            analytics.pageView();
            setAnalytics(true);
        }

        msgErro();
        loadTerminalContent();
    }, [loadTerminalContent, msgErro, loadAnalytics]);

    return (
        <Grid container component="main" className={classes.root}>
            <Helmet>
                <title data-react-helmet="true">Entrar</title>
                <meta name="description" content="Entrar, ou se cadastrar, no site para ter maior suporte nas aulas." data-react-helmet="true" />
                <link rel="canonical" href={`/login`} data-react-helmet="true" />
            </Helmet>

            <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />
            <CssBaseline />
            <Grid item xs={false} sm={false} md={7} className={classes.image} />
            <Grid item xs={12} sm={12} md={5} elevation={6} >
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>

                    <Typography component="h1" variant="h5" >
                        <span id="welcome"></span>
                    </Typography>

                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            variant="outlined"
                            label={t('input.email')}
                            name="user"
                            margin="normal"
                            required
                            autoFocus
                            fullWidth
                            size="small"
                            autoComplete="email"
                            inputRef={register({
                                pattern: {
                                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message: t('input.invalid', { input: t('input.email') }),
                                }
                            })}
                            placeholder={t('input.email')}
                            error={!!errors.user}
                            helperText={!!errors.user && errors.user.message}
                        />

                        <TextField
                            variant="outlined"
                            label={t('input.password')}
                            name="password"
                            margin="normal"
                            required
                            fullWidth
                            size="small"
                            type="password"
                            inputRef={register({
                                required: t('input.required')
                                , minLength: {
                                    value: 6,
                                    message: t('input.invalid.size', { from: '6', to: '15' })
                                }, maxLength: {
                                    value: 15,
                                    message: t('input.invalid.size', { from: '6', to: '15' })
                                }
                            })}
                            placeholder={t('input.password')}
                            error={!!errors.password}
                            helperText={!!errors.password && errors.password.message}
                        />

                        <Button fullWidth disabled={loading} variant="contained" type="submit" color="primary" className={classes.submit}>{t('button.login')}</Button>

                        {/*<FacebookLogin
                            appId="1999067080285829"
                            autoLoad={true}
                            fields="name,email,picture,public_profile"
                        onClick={clickedFacebook}*/}

                        <Grid container>
                            <Grid item xs>
                                <Link href="/forgotPassword" variant="body2">
                                    {t('text.forgot.password')}
                                </Link>
                                <br />
                                <Link href="/forgotPassword" variant="body2">
                                    {t('text.validate.email')}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {t('text.sign.in')}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <CopyrightFrame />
                        </Box>
                    </form>
                </div >
            </Grid>
        </Grid >
    );
}

export default Login;