import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Typed from 'typed.js';
import Client from '../../base/Client';
import Utils from '../../base/Utils';
import ErrorDialog from '../../components/error-dialog';
import IntersectionObserver from "../../components/intersection-observer";
import ScaleBox from "../../components/scale-box";

function HomeSubscriptionsFrame(props) {
    const { t } = useTranslation();

    const useStyles = makeStyles((theme) => ({
        main: {
            backgroundImage: 'url(/assets/img/home/learn_back.jpg)',
            display: 'inline-block',
            padding: theme.spacing(8) + 'px ' + theme.spacing(4) + 'px',
            width: '100%',
        },
        title: {
         
            display: 'inline-block',
           // padding: theme.spacing(2),
           // paddingTop: theme.spacing(0),
            textAlign: 'center',
            width: '100%',
            color: theme.palette.text.secondary,
            '@media (max-width:780px)': {
                '& h4': {
                    fontSize: "18pt",
                },
            },
        },
        description: {
            display: 'inline-block',
            marginBottom: theme.spacing(0),
            padding: theme.spacing(0),
            paddingBottom: theme.spacing(2),
            width: '100%',
        },
        root: {
            display: "flex",
            paddingBottom: theme.spacing(2),
            boxSizing: 'border-box',
        },
        paper: {
            padding: theme.spacing(2),
        },
        logos: {
            width: '128px',
            height: '128px',
        },
        cardRoot: {
            display: 'flex',
            flexDirection: 'column',
            height: '100% !important',
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.background.selected,
            },
            '&:hover *': {
            },
        },
        cardContent: {
            flex: '30%',
            verticalAlign: 'top',
            paddingBottom: '0px',
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        cardLogo: {
            background: '#403f3f',
            flex: '40%',
            textAlign: 'center',
            verticalAlign: 'top',
        },
        cardFooter: {
            flex: '10%',
            verticalAlign: 'bottom',
            alignSelf: 'center',
            padding: theme.spacing(2),
            '& button': {
                backgroundColor: '#403f3f',
                paddingBottom: theme.spacing(1),
                paddingTop: theme.spacing(1),
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            }
        },
        newsLogo: {
            height: '32px',
            width: '32px',
            display: 'none'
        }
    }));

    const classes = useStyles();
    const [subscriptions, setSubscriptions] = React.useState(null);
    const [doSubscription, setDoSubscription] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const utils = new Utils();

    const loadContent = useCallback(
        () => {
            const client = new Client();

            if (subscriptions === null) {
                setLoading(true);
                client.send("get", "/priv/v1/product/registrations", null)
                    .then(data => {
                        if (data.status === 204) {
                            setDoSubscription(true);

                            //Escreve terminal
                            new Typed('#descrSub', {
                                strings: [t('text.no.subscriptions')],
                                typeSpeed: 30,
                                onBegin: function (pos, self) {
                                    let cursor = document.querySelectorAll(".typed-cursor");
                                    if (cursor !== null) {
                                        cursor.forEach((n) => {
                                            n.remove();
                                        });
                                    }
                                },
                            });

                        } else {
                            setDoSubscription(false);

                            if (data.data !== undefined) {
                                setSubscriptions(data.data);
                            }
                        }
                    }).catch(error => {
                        setErrorMsg(error.customMsg);
                    }).finally(() => {
                        setLoading(false);
                    });
            }
        },
        [t, subscriptions],
    );

    const handleClose = () => {
        setErrorMsg("");
    }

    const handlerGotoSearch = () => {
        window.location.href = "/product/search";
    }

    useEffect(() => {
        loadContent();

    }, [loadContent]);

    const handlerClick = (productId) => {
        window.location.href = `/product/${productId}`;
    }

    const videosHtmls = (row, i) => {

        let visibility = 'hidden';

        const dateViewed = utils.formatUTCStringToDate(row.lastViewedDate);
        const lastChangedDate = utils.formatUTCStringToDate(row.lastChangedDate);

        if (lastChangedDate > dateViewed) {
            visibility = 'block';
        }

        return (<Grid item xs={12} sm={6} md={4} key={i} className={classes.gridItem}>
            <IntersectionObserver key={i} css={{ height: '100%' }}>
                <ScaleBox delayOrder={i} css={{ height: '100%' }}>
                    <Card raised onClick={() => handlerClick(row.product.id)} className={classes.cardRoot}>
                        <CardContent className={classes.cardLogo}>
                            <img className={classes.logos} src={row.product.logo} alt="Logo"></img>
                        </CardContent>

                        <CardContent className={classes.cardContent}>
                            <Typography color="textSecondary" gutterBottom >
                                {row.product.title}
                            </Typography>
                            <Typography variant="body2" component="p" >
                                {row.product.description}
                            </Typography>
                        </CardContent>

                        <CardActions className={classes.cardFooter}>
                            <Button color="primary" onClick={() => handlerClick(row.product.id)} size="small">{t('text.continue')}</Button>
                            <img className={classes.newsLogo} src="/assets/img/newIcon.png" alt="New" style={{ display: visibility }}></img>
                        </CardActions>
                    </Card>
                </ScaleBox>
            </IntersectionObserver>
        </Grid>)
    }

    const contentNotLoad = (row, i) => {
        return (<Grid item xs={12} sm={4} key={i}>
            <Skeleton variant="rect" width='100%' height={118} />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
        </Grid>)
    }

    return (
        <div className={classes.main}>
            <ErrorDialog onClose={handleClose} errorMsg={errorMsg} />

            <div className={classes.title}>
                <Typography variant="h4"> :: {t('text.subscriptions')} :: </Typography>
            </div>

            {doSubscription === false && <div className={classes.description}><Typography variant="subtitle1">{t('text.subscription.description')}</Typography>  </div>}

            <div >
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    className={classes.root}
                >
                    {doSubscription === false ? (
                        loading === false && (subscriptions !== null && subscriptions.length > 0) ? (
                            subscriptions.map(videosHtmls)
                        ) : (
                            [...Array(3)].map(contentNotLoad)
                        )
                    ) : (
                        <Grid item xs>
                            {/* <Paper className={classes.paper}> */}
                            <Typography variant="body1" color="primary" gutterBottom>
                                <span id="descrSub"></span>
                            </Typography>

                            <Button color="primary" variant="contained" onClick={() => handlerGotoSearch()} size="small">{t('button.search')}</Button>
                            {/* </Paper> */}
                        </Grid>
                    )}
                </Grid>
            </div>
        </div >
    );
}

export default HomeSubscriptionsFrame;